import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import useIsFirstRender from 'hooks/useFirstRender'
import authApi from 'api/auth'
import { MetaAccessContent } from 'components/Settings/Meta'
import { useTranslation } from 'react-i18next'
import { ConnectContextProvider, useConnectContext } from 'components/Settings/Meta/connect'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'components/Shared/ErrorFallback'
import ErrorMessage from 'components/Shared/ErrorMessage'
import Notice from 'components/Shared/Notice'

export function AnonymousFBLoginContent({ match }) {
  const { language } = match.params
  const [accessToken, setAccessToken] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { connections } = useConnectContext()
  const firstRender = useIsFirstRender()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    // set language
    if (!firstRender) return

    if (language && ['en', 'de'].includes(language)) {
      i18n.changeLanguage(language)
    }
  }, [firstRender, i18n, language])

  useEffect(() => {
    // Exchange auth_code for access token, and set a partner session
    if (!firstRender) return

    const searchParams = new URLSearchParams(window.location.search)
    const authCodeParam = searchParams.get('code')

    if (!authCodeParam) {
      // set error code
      setError('fd.error.missingAuthCode')
    } else {
      // Call API to exchange auth_code for access token
      // This is a simplified example; replace with your actual API call
      setLoading(true)
      authApi
        .getAnonymousToken(authCodeParam)
        .then((response) => {
          setLoading(false)
          const { data, error } = response
          if (error) {
            console.error(error)
            setError('fd.error.invalidAuthCode')
            return
          }
          setAccessToken(data.access_token)
          // save the access token in session storage
          sessionStorage.setItem('anonymous-session', JSON.stringify(data))
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setError('fd.error.invalidAuthCode')
        })
    }
  }, [firstRender])

  const connected = useMemo(() => connections.length > 0, [connections])

  return (
    <Layout title="Facebook Login">
      <Helmet bodyAttributes={{ class: '' }} />
      <div>
        <main className="third-party-fb-login pt-5">
          <div className="container-fluid h-100 mb-5">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              <div className="d-flex align-items-center justify-content-center my-4">
                <Notice
                  isVisible={connected}
                  isWarning={false}
                  className="notice--light w-100"
                  message={t('Settings.MetaAccess.anonymous.connected')}
                />
              </div>
              {error && (
                <div>
                  <ErrorMessage danger>{t('common.genericError')}</ErrorMessage>
                </div>
              )}
              {loading && <LoadingSpinner showText={false} />}
              {accessToken && (
                <div className="col-12 col-lg-9 mx-auto settings-page__container">
                  <div className="meta settings-page__card">
                    <MetaAccessContent isAnonymous />
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

AnonymousFBLoginContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      language: PropTypes.string,
    }),
  }),
}

export function AnonymousFBLogin({ match }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ConnectContextProvider>
        <AnonymousFBLoginContent match={match} />
      </ConnectContextProvider>
    </ErrorBoundary>
  )
}

AnonymousFBLogin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      language: PropTypes.string,
    }),
  }),
}
