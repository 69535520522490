import { parsePartnerIntegrations } from 'api/models'
import { api, makeApiRequest } from '../_init'

/**
 * @typedef {import('./types.js').FBAsset} FBAsset
 */

const metaAuthorizationApi = {
  postAuthorizationCode: async (code) => {
    return await makeApiRequest(api, 'post', '/integrations/connect/facebook/authorize', code)
  },
  postAccessToken: async (accessToken) => {
    return await makeApiRequest(api, 'post', '/integrations/connect/facebook/token', {
      access_token: accessToken,
    })
  },
  getStatus: async () => {
    return await makeApiRequest(api, 'get', '/integrations/connect/facebook/status')
  },
  getTokenStatus: async (tokenId) => {
    return await makeApiRequest(api, 'get', `/integrations/connect/facebook/token-status/${tokenId}`)
  },
  deactivate: async (tokenType, platformUserId) => {
    return await makeApiRequest(api, 'delete', '/integrations/connect/facebook/token', {
      token_type: tokenType?.toUpperCase(),
      platform_user_id: platformUserId,
    })
  },
  getPartnerToken: async (code, redirectUri) => {
    return await makeApiRequest(api, 
      'post',
      `/vendor/partners/tokens?code=${code}&redirect_uri=${redirectUri}`,
    )
  },
}

export const metaAssetsApi = {
  /**
   * List all assets
   * @param {object} params
   * @param {boolean} params.is_enabled
   * @returns {Promise<{data: Array<FBAsset>}>}
   */
  listAssets: async ({ is_enabled, platformUserIds } = {}) => {
    let endpoint = '/integrations/connect/facebook/assets'
    const params = new URLSearchParams()
    if (typeof is_enabled === 'boolean') {
      params.append('is_enabled', is_enabled)
    }
    if (platformUserIds) {
      params.append('platform_user_ids', platformUserIds.join(','))
    }
    if (params.toString()) {
      endpoint += `?${params.toString()}`
    }
    const response = await makeApiRequest(api, 'get', endpoint)
    if (response.status === 200) {
      return { ...response, data: parsePartnerIntegrations(response.data) }
    }

    return response
  },
  triggerAssetRefetch: async ({ platformUserIds } = {}) => {
    const url = `/integrations/connect/facebook/assets?platform_user_ids=${platformUserIds.join(',')}`
    return await makeApiRequest(api, 'post', url)
  },
  getAssetTaskStatus: async (taskId) => {
    return await makeApiRequest(api, 'get', `/integrations/connect/facebook/task-status/${taskId}`)
  },
  toggleAsset: async (assetId, enabled) => {
    return await makeApiRequest(api, 'put', `/integrations/connect/facebook/assets/${assetId}`, {
      enabled,
    })
  },
  enrichAssets: async (accounts) => {
    return await makeApiRequest(api, 'post', '/integrations/connect/facebook/assets/enrich', {
      assets: accounts,
    })
  },
}

export default metaAuthorizationApi
