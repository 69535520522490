import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useIsFirstRender from 'hooks/useFirstRender.js'
import { objectToParams, returnErrorMessage } from 'utils/helpers.js'
import ErrorFallback from 'components/Shared/ErrorFallback.jsx'
import FallingDots from 'components/Shared/FallingDots.js'
import PortalModal from 'components/Shared/PortalModal.jsx'
import { CancelledIcon } from 'images'

import { Connection } from './Connection.jsx'
import { tokenTypes } from './util.js'
import {
  ConnectContextProvider,
  actions,
  dispatchDeactivate,
  dispatchGetAssets,
  dispatchPostAuthCode,
  dispatchSetAnonymous,
  dispatchSetError,
  useConnectContext,
  useConnectDispatchContext,
} from './connect.js'

export function MetaAccessContent({ isAnonymous }) {
  const { t } = useTranslation()
  const errorRef = useRef(null)
  const popupRef = useRef(null)
  const user = useSelector  (state => state.user)
  const { error, warning, loading, assets } = useConnectContext()
  const { dispatch } = useConnectDispatchContext()
  const firstRender = useIsFirstRender()
  const [fbSdkFailedToLoad, setFbSdkFailedToLoad] = React.useState(null)
  const [refetchOnFocus, setRefetchOnFocus] = React.useState(false)

  useEffect(() => {
    dispatch({ type: actions.GET_STATUS })
  }, [dispatch])

  useEffect(() => {
    if (isAnonymous) {
      dispatchSetAnonymous(dispatch)
    }
  }, [dispatch, isAnonymous])

  useEffect(() => {
    if (window.FB) return
    const timeout = setTimeout(() => {
      setFbSdkFailedToLoad(!window.FB)
    }, 5000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    dispatchGetAssets(dispatch)
  }, [dispatch])

  useEffect(() => {
    const refreshStatus = () => {
      if (user.isLogged && refetchOnFocus) {
        dispatch({ type: actions.GET_STATUS })
        setRefetchOnFocus(false)
      }
    }
    window.addEventListener('focus', refreshStatus)
    return () => window.removeEventListener('focus', refreshStatus)
  }, [dispatch, user.isLogged, refetchOnFocus])

  useEffect(() => {
    if ((error || warning) && errorRef.current) {
      errorRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [error, warning, errorRef])

  useEffect(() => {
    if (!firstRender) return

    window.addEventListener('message', (event) => {
      if (!popupRef.current) {
        return
      }

      if (event.data.type === 'fb_callback') {
        popupRef.current.close()
        popupRef.current = null
        if (event.data.code) {
          dispatchPostAuthCode(dispatch, event.data.code, `${window.location.origin}/fb-callback`)
        } else {
          const { error, error_reason, error_description } = event.data
          console.log(
            'Facebook login was cancelled or did not authorize the app.',
            error,
            error_reason,
            error_description,
          )
          dispatchSetError(dispatch, { localizedMessage: t('Settings.MetaAccess.loginCancelled') })
        }
      }
    })
  }, [dispatch, t, firstRender])

  const onFacebookLogin = useCallback(
    (configuration, statePrefix, rerequest = false) => {
      setRefetchOnFocus(true)
      const authSession = {
        state: `${statePrefix}_${Math.random().toString(36).substring(7)}`,
        redirectUri: `${window.location.origin}/fb-callback`,
      }
      sessionStorage.setItem('authSession', JSON.stringify(authSession))
      const dialogParams = {
        client_id: '440228506824513',
        redirect_uri: authSession.redirectUri,
        state: authSession.state,
        response_type: 'code',
        scope: configuration.scopes.join(','),
        config_id: configuration.id,
        auth_type: rerequest ? 'rerequest' : undefined,
        display: 'popup',
      }

      const facebookLoginUrl = `https://www.facebook.com/dialog/oauth${objectToParams(dialogParams)}`

      // open Facebook Login in a popup
      const width = 600
      const height = 600
      const left = (window.innerWidth - width) / 2
      const top = (window.innerHeight - height) / 2
      const popupParams = `width=${width},height=${height},left=${left},top=${top},popup=1,scrollbars=1,menubar=0,toolbar=0,status=0,resizable=1`
      const popup = window.open(facebookLoginUrl, 'fbLoginWindow', popupParams)
      if (!popup) {
        dispatchSetError(dispatch, { localizedMessage: t('Settings.MetaAccess.popupBlocked') })
      } else {
        popup.focus()
        popupRef.current = popup
      }
    },
    [dispatch, t],
  )

  const onLogout = useCallback(
    (tokenType) => {
      // We have 2 modes of login now (user and system). We don't want to logout from Facebook if the user is still logged in.
      // TODO: Logout from Facebook only if the user is not connected with a user and system token.
      // window.FB && window.FB.getLoginStatus(function (response) {
      //     console.log('Facebook login status:', response)
      //     if (response.status === 'connected') {
      //         window.FB.logout(function (response) {
      //             console.log('User is now logged out from Facebook.', response)
      //         })
      //     }
      // }, true /* force a roundtrip to Facebook */)
      dispatchDeactivate(dispatch, tokenType)
    },
    [dispatch],
  )

  const showLoading = useMemo(() => loading && assets?.length === 0, [loading, assets])

  if (fbSdkFailedToLoad) {
    return (
      <div className="settings-page__section settings-page__section--bb-0 d-flex flex-column gap-2">
        <p className="alert alert-danger mb-0">{t('Settings.MetaAccess.fbSdkMissing')}</p>
      </div>
    )
  }

  return (
    <>
      {(error || warning) && (
        <div
          className="settings-page__section settings-page__section--bb-0 d-flex flex-column gap-2 mb-2"
          ref={errorRef}
        >
          {error && (
            <p
              className={`alert mb-0 ${error ? 'alert-danger' : 'alert-warning'} d-flex justify-content-between align-items-center`}
            >
              {error.localizedMessage ?? t(returnErrorMessage(error.message))}
              <button type="button" className="alert_close-btn" onClick={() => dispatchSetError(dispatch, null)}>
                <CancelledIcon className="alert_close-btn_icon" />
              </button>
            </p>
          )}
          {warning && <p className="alert alert-warning mb-0">{warning}</p>}
        </div>
      )}
      <div className="d-flex flex-column gap-2">
        <Connection tokenType={tokenTypes.user} onFacebookLogin={onFacebookLogin} onLogout={onLogout} />
        <Connection tokenType={tokenTypes.system} onFacebookLogin={onFacebookLogin} onLogout={onLogout} />
        <PortalModal isOpen={showLoading} onClickOutside={() => {}}>
          <div className="content compact pt-0 text-center" style={{ maxWidth: '400px' }}>
            <FallingDots classNames="autofill_falling-dots" />
            <div className="text-center">
              <p className="body-txt light larger mb-1">{t('Settings.MetaAccess.loadingTitle')}</p>
              <p className="body-txt light larger mb-2">{t('Settings.MetaAccess.loadingDescription')}</p>
            </div>
          </div>
        </PortalModal>
      </div>
    </>
  )
}

MetaAccessContent.propTypes = {
  isAnonymous: PropTypes.bool,
}

export default function MetaAccess() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ConnectContextProvider>
        <MetaAccessContent />
      </ConnectContextProvider>
    </ErrorBoundary>
  )
}
