import { fileStatus } from 'components/Recommendations/Campaign/Media/media'
import {
  validateLocationTargeting,
  isLocationTargetingEmpty,
  TARGETING_MODES,
} from 'components/Recommendations/Campaign/Edit/CampaignForm/Targeting/targeting'
import { hashObjectValues } from 'utils/helpers'
import { AssetClass, Platform } from 'api/models'

/** @typedef {import('api/connect/types.js').FBAsset} FBAsset */
/** @typedef {import('api/connect/types.js').AssetClassType} AssetClassType */
/** @typedef {import('api/connect/types.js').PlatformType} PlatformType */

export function calculateSetupCreativesFromUpdatedTCCreatives(
  creatives,
  tc,
  tc_run_id,
  tcCreatives,
) {
  const otherCreatives = creatives.filter((c) => c.tc !== tc).map((c) => ({ ...c }))

  const media = tcCreatives.media
    ?.filter((x) => x.status === fileStatus.success)
    .map((m) => ({
      id: m.id,
      url: m.url,
      size: m.size,
      type: m.type,
      index: m.index,
      width: m.width,
      height: m.height,
      status: m.status,
      filename: m.filename,
    }))
  const newCreatives = [...otherCreatives, { ...tcCreatives, tc, tc_run_id, media }]

  if (tcCreatives.call_to_action) {
    // set call to action on all creatives without a call to action
    newCreatives.forEach((c) => {
      if (!c.call_to_action) {
        c.call_to_action = tcCreatives.call_to_action
      }
    })
  }

  if (tcCreatives.destination_url) {
    // set destination url on all creatives without a destination url
    newCreatives.forEach((c) => {
      if (!c.destination_url || c.destination_url.filter(Boolean).length === 0) {
        c.destination_url = [...tcCreatives.destination_url]
      }
    })
  }

  return newCreatives
}
export function getDisabledVariationHashes(setup) {
  if (!setup) {
    return {}
  }

  const { creatives } = setup
  const integrityHashes = creatives.reduce((acc, c) => {
    acc[c.tc] = []
    return acc
  }, {})

  const disabledVariations = creatives.reduce((acc, c) => {
    acc[c.tc] = c.excluded_variations
    return acc
  }, {})

  try {
    for (const tc of Object.keys(disabledVariations)) {
      const tcCreative = creatives.find((c) => c.tc === tc)
      for (const variation of disabledVariations[tc]) {
        const [headlineIndex, bodyIndex, mediaIndex, destinationUrlIndex] = variation
          .split('')
          .map(Number)
        const headline = tcCreative.headline[headlineIndex - 1]
        const body = tcCreative.body[bodyIndex - 1]
        const media = tcCreative.media[mediaIndex - 1]
        const destinationUrl = tcCreative.destination_url[destinationUrlIndex - 1]

        const hash = hashObjectValues({
          headline,
          body,
          media: media.url,
          destinationUrl,
        })

        integrityHashes[tcCreative.tc].push({
          [variation]: hash,
        })
      }
    }
  } catch (error) {
    console.error('Failed to init disabled variations cache', error)
  }

  return integrityHashes
}
export function _checkDisabledVariationsIntegrity(
  creatives,
  integrityHashes,
  currentDisabledVariations,
) {
  const disabledVariations = creatives.reduce((acc, creative) => {
    acc[creative.tc] = creative.excluded_variations
    return acc
  }, {})

  for (const tc of Object.keys(disabledVariations)) {
    const tcCreative = [creatives].flat().find((c) => c.tc === tc)
    for (const variation of disabledVariations[tc]) {
      if (currentDisabledVariations && !currentDisabledVariations[tc]?.includes(variation)) {
        // Disabled variation was removed
        continue
      }
      const [headlineIndex, bodyIndex, mediaIndex, destinationUrlIndex] = variation
        .split('')
        .map(Number)
      const headline = tcCreative.headline[headlineIndex - 1]
      const body = tcCreative.body[bodyIndex - 1]
      const media = tcCreative.media[mediaIndex - 1]
      const destinationUrl = tcCreative.destination_url[destinationUrlIndex - 1]

      const hash = hashObjectValues({
        headline,
        body,
        media: media.url,
        destinationUrl,
      })

      const originalHash = integrityHashes[tc]?.find((x) => x[variation])

      // no original hash found means this disabled variation is new
      if (originalHash && hash !== originalHash[variation]) {
        return false
      }
    }
  }

  return true
}
/**
 * Builds the targeting payload based on a specific - and updated - tc targeting.
 * It initializes all tcs without targeting or with invalid targeting with the new valid targeting of the source tc.
 *
 * @param {Object} setup - The setup object.
 * @param {Object} tcTargeting - The source targeting object.
 */
export function buildTargetingPayload(setup, tcTargeting) {
  const { tc, targetingMode } = tcTargeting
  const { targeting, creatives } = setup
  const tcs = setup.creatives.map((x) => ({ tc: x.tc, tc_run_id: x.tc_run_id }))
  const resultPayload = []
  const _hasCustomAudience = hasCustomAudience(tc, creatives)

  for (const tasteCluster of tcs) {
    if (tasteCluster.tc === tcTargeting.tc) {
      resultPayload.push(tcTargeting)
      continue
    }

    const { postalCodes: { countries } = {}, rangeLocations: { included, excluded } = {} } =
      tcTargeting
    const currentTcTargeting = targeting.find((y) => y.tc === tasteCluster.tc)
    if (
      !currentTcTargeting ||
      !currentTcTargeting.targetingMode ||
      !validateLocationTargeting(
        targeting,
        tasteCluster.tc,
        tasteCluster.tc_run_id,
        _hasCustomAudience,
      ).valid ||
      isLocationTargetingEmpty(currentTcTargeting)
    ) {
      const tcPayload = { tc: tasteCluster.tc, tc_run_id: tasteCluster.tc_run_id, targetingMode }
      if (targetingMode === TARGETING_MODES.postalCodes && countries) {
        tcPayload.postalCodes = { countries }
      } else if (targetingMode === TARGETING_MODES.rangeLocations && (included || excluded)) {
        tcPayload.rangeLocations = { included, excluded }
      }

      resultPayload.push(tcPayload)
    } else {
      resultPayload.push(currentTcTargeting)
    }
  }

  return resultPayload
}

export function hasCustomAudience(tc, creatives) {
  const tcCreative = creatives.find((x) => x.tc === tc)
  return !!tcCreative?.audience_id
}

/**
 * Retrieves the available pages based on the provided ad account
 *
 * @param {Array<FBAsset>} accounts - The list of available assets.
 * @param {FBAsset} adAccount - The selected ad account.
 * @param {boolean} metaConnectionStatus - The meta connection status.
 */
export function getAvailablePages(accounts, adAccount, metaConnectionStatus) {
  if (!metaConnectionStatus) {
    return accounts.filter(
      (x) =>
        x.asset_class === AssetClass.profile && x.platform === Platform.facebook && x.is_enabled,
    )
  }

  if (!adAccount) {
    return []
  }

  // Profiles are restricted by ad accounts
  return accounts.filter(
    (x) =>
      x.asset_class === AssetClass.profile &&
      x.platform === Platform.facebook &&
      x.is_enabled &&
      x.connected_assets.some(
        (y) =>
          y.asset_id === adAccount.asset_id &&
          y.asset_class === AssetClass.ad_account &&
          y.platform === Platform.facebook &&
          y.is_active,
      ),
  )
}

/**
 * Retrieves the available pixels based on the provided ad account
 *
 * @param {Array<FBAsset>} accounts - The list of available assets.
 * @param {FBAsset} adAccount - The selected ad account.
 * @param {boolean} metaConnectionStatus - The meta connection status.
 */
export function getAvailablePixels(accounts, adAccount, metaConnectionStatus) {
  if (!metaConnectionStatus) {
    return accounts.filter(
      (x) => x.asset_class === AssetClass.pixel && x.platform === Platform.facebook && x.is_enabled,
    )
  }

  if (!adAccount) {
    return []
  }

  // Pixels are restricted by ad accounts
  return accounts.filter(
    (x) =>
      x.asset_class === AssetClass.pixel &&
      x.platform === Platform.facebook &&
      x.is_enabled &&
      x.connected_assets.some(
        (y) =>
          y.asset_id === adAccount.asset_id &&
          y.asset_class === AssetClass.ad_account &&
          y.platform === Platform.facebook &&
          y.is_active,
      ),
  )
}

/**
 * Retrieves the available instagram accounts based on the provided ad account and facebook profile
 *
 * @param {Array<FBAsset>} accounts - The list of available assets.
 * @param {FBAsset} profile - The selected facebook profile.
 * @param {boolean} metaConnectionStatus - The meta connection status.
 */
export function getAvailableInstagramAccounts(accounts, page, metaConnectionStatus) {
  if (!metaConnectionStatus) {
    return accounts.filter(
      (x) =>
        x.asset_class === AssetClass.profile && x.platform === Platform.instagram && x.is_enabled,
    )
  }

  // Instagram profiles are restricted by Facebook profiles
  if (!page) {
    return []
  }

  return accounts.filter(
    (x) =>
      x.asset_class === AssetClass.profile &&
      x.platform === Platform.instagram &&
      x.is_enabled &&
      x.connected_assets.some(
        (y) =>
          y.asset_id === page.asset_id &&
          y.asset_class === AssetClass.profile &&
          y.platform === Platform.facebook &&
          y.is_active,
      ),
  )
}
