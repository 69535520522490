import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useConnectDispatchContext } from './connect.js'

/** @typedef {import('./types.js').Asset} Asset */

/**
 * 
 * @param {Object} props
 * @param {Asset} props.asset
 * @returns 
 */
export default function Asset({ asset, onToggle, disabled = false }) {
  const { t } = useTranslation()
  const { isAssetOwner } = useConnectDispatchContext()
  const assetOwner = isAssetOwner(asset)

  return (
    <div className="settings-page__sectionBody">
      <div className="settings-page__sectionWrapper">
        <div className="settings-page__sectionState position-relative">
          <div className="d-flex align-items-center gap-2">
            <label className="custom-check setting-checkbox custom-check--navy">
              <input
                className="custom-check__input"
                id={asset.asset_id}
                type="checkbox"
                checked={asset.is_enabled}
                onChange={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onToggle(asset.id, !asset.is_enabled)
                }}
                disabled={disabled || !assetOwner}
              />
              <span className="custom-check__box"></span>
            </label>
            <span>
              {asset.asset_label} (ID: {asset.asset_id})
            </span>
            <span
              className={cn('asset__active-label', {
                'asset__active-label--active': asset.is_active,
              })}
            >
              {asset.is_active ? t('common.active') : t('common.inactive')}
            </span>
          </div>
        </div>
        {asset.usernames.filter(Boolean).length > 0 && (
          <div className="settings-page__sectionColumn asset__usernames gap-1">
            <div className="asset__usernames__label">
              {t('Settings.MetaAccess.Assets.connectedBy')}:{' '}
            </div>
            {asset.usernames.map((username) => (
              <div className="asset__usernames__item" key={username}>
                {username}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

Asset.propTypes = {
  asset: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
