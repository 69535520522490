import { api, apiURL, cr_api, makeApiRequest } from './_init'
import { LOADING, LOADING_PAGE, UPDATE, OVERWRITE, FAIL } from '../reducers/default'
import moment from 'moment'
import qs from 'qs'
import { parseGroupedLocations } from './models'
import axios from 'axios'
import { getAuthorizationHeader } from './_token'
import store from 'store'
import constants from '../constants'

const formatApiEvent = (event) => {
  return {
    eid: event.id,
    id: event.id,
    title: event.title,
    date: event.start_date_time,
    benchmark:
      event.benchmark && event.benchmark.prediction ? event.benchmark.prediction.benchmark : 0,
    now: event.benchmark && event.benchmark.prediction ? event.benchmark.prediction.value : 0,
    state: event.benchmark ? event.benchmark.state : null,
    frontend_status: event.frontend_status ? event.frontend_status : null,
    hall_name: event.hall_name,
    venue_name: event.venue_name,
    city: event.city,
    capacity: event.capacity,
    has_active_campaigns: event.has_active_campaigns,
    client_campaign_status: event.client_campaign_status,
    campaign_optimisation_status: event.campaign_optimisation_status,
    campaign_event_series: event.campaign_event_series,
    campaign_status: event.campaign_status,
    campaign_stop_time: event.campaign_stop_time,
    category: event.category,
  }
}

export const fetchFlaggedEvents = async (dispatch) => {
  dispatch({ type: LOADING })

  try {
    // axios has no good way of serializing query params of the same key e.g. (&location=foo&location=bar)
    // https://github.com/axios/axios/issues/709
    const result = await api.get('/events/flagged/')

    const response = {
      total: result.data.total,
      page: 0,
      maxPage: result.data.total,
      items: result.data.items.map((event) => formatApiEvent(event)),
    }

    // remove undefined from
    response.items = response.items.filter((e) => Boolean(e))

    dispatch({ type: OVERWRITE, payload: response })
  } catch (error) {
    dispatch({ type: FAIL, payload: 'Error fetching data.' })
  }
  dispatch({ type: UPDATE, payload: [] })
}

export const fetchEvents = async (dispatch, settings = {}) => {
  const { cancelToken, ...otherSettings } = settings
  const {
    page = 1,
    search = null,
    until = moment().add(2, 'year').format('DD-MM-YYYY'),
    status = null,
    marketing_campaign_status = null,
    since = moment(new Date()).subtract(3, 'year').format('DD-MM-YYYY'),
    cities = [],
    vh = [],
    source,
    campaigns,
    include_archived = false,
    frontend_status = ['DEFAULT', 'FLAGGED'],
    flagged_to_top = false,
    removeCancelled = false,
    descending = false,
    exclude_products = false,
  } = otherSettings

  dispatch({ type: page > 1 ? LOADING_PAGE : LOADING })

  try {
    const PAGE_LIMIT_ENV = Number(process.env.REACT_APP_PAGE_LIMIT)
    const PAGE_LIMIT = PAGE_LIMIT_ENV > 0 ? PAGE_LIMIT_ENV : 50

    const params = {
      page,
      limit: PAGE_LIMIT,
      q: search,
      include_archived,
      until,
      status,
      marketing_campaign_status,
      since,
      simulated: source,
      campaigns,
      city: cities,
      vh,
      frontend_status,
      flagged_to_top,
      exclude_products,
      descending,
    }

    // remove empty key pair values so the serialization excludes them
    for (const propName in params) {
      if (params[propName] === null || params[propName] === undefined || params[propName] === '') {
        delete params[propName]
      }
    }

    // axios has no good way of serializing query params of the same key e.g. (&location=foo&location=bar)
    // https://github.com/axios/axios/issues/709
    // API call with optional cancelToken
    const axiosConfig = {
      params: { ...params },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    }

    if (cancelToken) {
      axiosConfig.cancelToken = cancelToken
    }

    const result = await api.get('/events/', axiosConfig)

    const response = {
      total: result.data.total,
      page: page,
      maxPage: Math.ceil(result.data.total / PAGE_LIMIT),
      items: result.data.items
        .map((event) => formatApiEvent(event))
        .filter((e) => {
          if (
            removeCancelled &&
            (e.frontend_status === 'CANCELLED' || e.frontend_status === 'HIDDEN')
          ) {
            return false
          }
          return true
        }),
    }

    // remove undefined from
    response.items = response.items.filter((e) => Boolean(e))

    dispatch({ type: page > 1 ? UPDATE : OVERWRITE, payload: response })
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message)
    } else {
      dispatch({ type: FAIL, payload: 'Error fetching data.' })
    }
  }
  dispatch({ type: UPDATE, payload: [] })
}

export const fetchEvent = async (dispatch, event_id) => {
  dispatch({ type: LOADING, payload: { id: event_id } })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}`)
      const event = formatApiEvent(result.data)
      dispatch({ type: UPDATE, payload: event })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}
export const fetchCREvent = async (dispatch, event_id) => {
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const { data } = await cr_api.get(`/events/${event_id}`)
      dispatch({ type: UPDATE, payload: data })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}
export const fetchVenues = async () => {
  try {
    const result = await api.get('/events/get_venues')
    if (result.status === 200) {
      return { data: result.data.venues, success: true }
    } else {
      return { error: result.status, success: false }
    }
  } catch (error) {
    return { error: error, success: false }
  }
}

export async function fetchGroupedVenues(dispatch, controller = null, options = {}) {
  dispatch({ type: LOADING })
  try {
    const result = await api.get('/events/venues', { signal: controller?.signal })
    if (result.status === 200) {
      dispatch({ type: UPDATE, payload: parseGroupedLocations(result.data, options) })
    } else {
      dispatch({ type: FAIL, payload: result.data })
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const updateEventStatus = async (event_id, frontendStatus) => {
  let domainModelStatus = 'RUNNING'

  if (frontendStatus === 'CANCELLED') {
    domainModelStatus = 'CANCELLED'
  }

  try {
    const result = await api.put(`/events/${event_id}/setup_frontend_status`, {
      frontend_status: frontendStatus,
    })
    if (result.status === 204) {
      const crResult = await cr_api.put(`/events/last_updated_on_fe/${event_id}`, {
        status: domainModelStatus,
      })

      if (crResult.status !== 200) {
        return { success: false, error: true, message: 'Failed to update status' }
      }

      return { success: true, error: null, message: '' }
    } else {
      return { success: false, error: true, message: 'Failed to update status' }
    }
  } catch (error) {
    return { success: false, error: true, message: 'Failed to update Status' }
  }
}

export const fetchEventBenchmark = async (dispatch, event_id) => {
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/benchmark`)

      const { now, prediction } = result.data

      const benchmark = {
        today: {
          value: now.value * 100,
          benchmark: now.benchmark * 100,
        },
        prediction: {
          value: prediction.value * 100,
          benchmark: prediction.benchmark * 100,
        },
      }

      dispatch({ type: UPDATE, payload: benchmark })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const fetchEventSuggestedTasteClusters = async (
  dispatch,
  event_id,
  budget,
  goal,
) => {
  let convertedBudget = budget
  dispatch({ type: LOADING })
  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/suggested_tcs`, {
        params: { budget: convertedBudget, goal: goal },
      })

      dispatch({ type: UPDATE, payload: result.data })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const fetchEventCampaignsExpectedValue = async (
  dispatch,
  event_id,
  goal,
  budget,
  controller = null,
) => {
  dispatch({ type: LOADING })

  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/campaigns_expected_value`, {
        params: { goal: goal, budget: budget },
        signal: controller?.signal,
      })

      dispatch({ type: UPDATE, payload: result.data.expected_value })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const fetchEventBudgetMinMax = async (dispatch, event_id, runtime) => {
  dispatch({ type: LOADING })

  if (event_id) {
    try {
      const result = await api.get(
        `/events/${event_id}/budget_min_max?runtime_in_days=${runtime}`,
        {},
      )

      dispatch({ type: UPDATE, payload: result.data })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}
export const fetchEventStatistics = async (dispatch, event_id) => {
  dispatch({ type: LOADING })

  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/statistics`, {})

      dispatch({ type: UPDATE, payload: result.data })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const fetchEventDefaultCampaignParameters = async (dispatch, event_id) => {
  dispatch({ type: LOADING })

  if (event_id) {
    try {
      const result = await api.get(`/events/${event_id}/default_campaigns_parameters`)

      dispatch({ type: UPDATE, payload: result.data })
    } catch (error) {
      dispatch({ type: FAIL })
    }
  }
}

export const initCampaignsSetup = async (dispatch, event_id, total_budget, goal, tcs) => {
  dispatch({ type: LOADING })
  try {
    const result = await api.put(`/events/${event_id}/init_campaigns_setup`, {
      total_budget: total_budget,
      goal: goal,
      tcs: tcs,
    })

    if (result.status === 204) {
      dispatch({ type: UPDATE, payload: result.data })
    } else {
      dispatch({ type: FAIL, payload: result.data })
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const resetCampaignsSetup = async (dispatch, event_id) => {
  dispatch({ type: LOADING })
  try {
    const result = await api.put(`/events/${event_id}/reset_campaigns_setup`, {})

    if (result.status === 204) {
      dispatch({ type: UPDATE, payload: result.data })
    } else {
      dispatch({ type: FAIL, payload: result.data })
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const submitUpdatedSalesData = async (data) => {
  try {
    const result = await api.post('/ingest/transaction_summary', data)
    if (result.status === 200) {
      return {
        success: true,
        error: false,
        statusCode: result.status,
      }
    } else {
      return {
        success: false,
        error: true,
        statusCode: result.status,
      }
    }
  } catch (error) {
    return {
      success: false,
      error: true,
      statusCode: error,
    }
  }
}

export const uploadSalesDocument = async (eventId, file) => {
  const partnerId = store.getState()?.user?.partnerId

  try {
    const bodyFormData = new FormData()
    bodyFormData.append('file', file)

    const response = await axios({
      method: 'post',
      url: `${apiURL}${constants.API_VERSION}/ingest/transaction_summary/from_excel?eid=${eventId}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getAuthorizationHeader(),
        'X-Preferred-Partner-Id': partnerId,
      },
    })

    if (response.status === 200) {
      return {
        success: true,
        error: false,
        statusCode: response.status,
      }
    }

    return {
      error_code: response.data?.error_code ?? response.data?.message,
      error_subcode: response.data?.error_subcode,
      success: false,
    }
  } catch (error) {
    return {
      error_code: error.response?.data?.error_code ?? error.message,
      error_subcode: error.response?.data?.error_subcode,
      success: false,
    }
  }
}

export const getEventSeries = async (dispatch, eventId) => {
  dispatch({ type: LOADING })

  try {
    const result = await api.get(`/events/${eventId}/series`)

    if (result.status === 200) {
      dispatch({ type: UPDATE, payload: result.data })
    } else {
      dispatch({ type: FAIL, payload: result.data })
    }
  } catch (error) {
    dispatch({ type: FAIL })
  }
}

export const getExportedSalesData = async (filters, currency, locale) => {
  const {
    search = null,
    until = moment().add(2, 'year').format('DD-MM-YYYY'),
    status = null,
    marketing_campaign_status = null,
    since = moment(new Date()).subtract(3, 'year').format('DD-MM-YYYY'),
    cities = [],
    vh = [],
    source,
    campaigns,
    include_archived = false,
    frontend_status = ['DEFAULT', 'FLAGGED'],
    flagged_to_top = false,
    exclude_products = false,
    descending = false,
  } = filters
  const dateFormats = {
    en: 'mm/dd/yyyy',
    de: 'dd.mm.yyyy',
    fr: 'dd/mm/yyyy',
  }
  const dateFormat = dateFormats[locale.split('-')[0].toLowerCase()] || 'dd.mm.yyyy'
  try {
    const params = {
      page: 1,
      q: search,
      include_archived,
      until,
      status,
      marketing_campaign_status,
      since,
      simulated: source,
      campaigns,
      city: cities,
      vh,
      frontend_status,
      flagged_to_top,
      exclude_products,
      descending,
      currency,
      date_format: dateFormat,
    }
    // remove empty key pair values so the serialization excludes them
    for (const propName in params) {
      if (params[propName] === null || params[propName] === undefined || params[propName] === '') {
        delete params[propName]
      }
    }
    const url = `${apiURL}${constants.API_VERSION}/daily_revenue_summary/sales_edit_export`
    const partnerId = store.getState()?.user?.partnerId
    const result = await axios.get(url, {
      headers: {
        Authorization: getAuthorizationHeader(),
        'X-Preferred-Partner-Id': partnerId,
      },
      params: {
        ...params,
      },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: 'blob',
    })
    return {
      success: true,
      data: result.data,
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    }
  }
}

export const importSalesData = async (file, onUploadProgress) => {
  const partnerId = store.getState()?.user?.partnerId
  try {
    const bodyFormData = new FormData()
    bodyFormData.append('file', file)
    const response = await axios({
      method: 'post',
      url: `${apiURL}${constants.API_VERSION}/daily_revenue_summary/sales_edit_export`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getAuthorizationHeader(),
        'X-Preferred-Partner-Id': partnerId,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        onUploadProgress(percentCompleted)
      },
    })
    if (response.status === 200) {
      return { success: true, error_codes: [] }
    }

    return {
      error_codes: parseImportErrors(response),
      success: false,
    }
  } catch (error) {
    return {
      error_codes: parseImportErrors(error.response),
      success: false,
    }
  }
}

function parseImportErrors(response) {
  const errorCodes = response.data?.validation_errors?.map((e) => e.code).filter(Boolean) ?? []
  if (response.data?.message?.startsWith('error.')) {
    errorCodes.push(response.data.message)
  }
  if (!errorCodes.length) {
    errorCodes.push('common.errors.inServerResponse')
  }

  return errorCodes
}

export const eventApi = {
  triggerItcGenerator: async (eventId) => {
    return await makeApiRequest(api, 'post', `/airflow_dag/${eventId}/itc_generator`)
  },
  getItcGeneratorStatus: async (eventId) => {
    return await makeApiRequest(api, 'get', `/airflow_dag/${eventId}/itc_generator`)
  },
  getCampaignStatusTaskState: async (eventId) => {
    return await makeApiRequest(api, 'get', `/events/${eventId}/client_campaign_status/task`)
  },
  changeClientCampaignStatus: async (eventId, status) => {
    return await makeApiRequest(
      api,
      'put',
      `/events/${eventId}/client_campaign_status?client_campaign_status=${status}`,
    )
  },
  changeCampaignOptimizationStatus: async (eventId, status) => {
    return await makeApiRequest(
      api,
      'put',
      `/events/${eventId}/campaign_optimisation_status?campaign_optimisation_status=${status}`,
    )
  },
  fetchEventAttributionModel: async (eventId) => {
    return await makeApiRequest(api, 'get', `/events/${eventId}/attribution_model`)
  },
  updateEventAttributionModel: async (eventId, attributionModel) => {
    return await makeApiRequest(api, 'put', `/events/${eventId}/attribution_model`, {
      model: attributionModel,
    })
  },
  fetchEventDefaultCampaignParameters: async (eventId) => {
    return await makeApiRequest(api, 'get', `/events/${eventId}/default_campaigns_parameters`)
  },
}
