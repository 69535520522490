import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import cn from 'classnames'

import { SecondaryButton } from 'components/Shared/Button'
import { actions, useConnectContext, useConnectDispatchContext } from './connect.js'
import { configurations, tokenTypes } from './util'
import { ConnectedIcon, SyncIcon, TickWrappedIcon } from 'images/index.jsx'
import PortalModal from 'components/Shared/PortalModal.jsx'
import { Button } from 'react-bootstrap'
import authApi from 'api/auth.js'
import { useToast } from 'components/Shared/AppToast/ToastProvider.jsx'
import ErrorMessage from 'components/Shared/ErrorMessage.jsx'
import { CancelIcon } from 'images'

const SelectedBox = () => {
  const { t } = useTranslation()

  return (
    <span className="pl-2 card-settings-account__value__verified">
      <ConnectedIcon />
      {t('Settings.MetaAccess.selected')}
    </span>
  )
}

function MetaAccessOptionLabel({ label, connected }) {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center">
      <span className="mr-2">{t(label)}</span>
      {connected && <SelectedBox />}
    </div>
  )
}

MetaAccessOptionLabel.propTypes = {
  label: PropTypes.string.isRequired,
  connected: PropTypes.bool,
}

export function CopyPrompt({ isOpen, onClose, code }) {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [copied, setCopied] = useState(false)
  const link = useMemo(
    () => `${window.location.origin}/${i18n.language}/meta-anonymous?code=${code}`,
    [code],
  )

  const copy = useCallback(() => {
    const textarea = document.getElementById('auth-link')
    textarea.focus()
    textarea.select()
    try {
      document.execCommand('copy')
      setError('')
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
        onClose(true)
      }, 2000)
    } catch (err) {
      console.error(err)
      setError('common.genericError')
      setCopied(false)
    }
  }, [onClose])

  return (
    <PortalModal isOpen={isOpen} onClickOutside={() => onClose(copied)}>
      <div
        className="meta_connection__copy-prompt content compact align-items-normal"
        style={{ maxWidth: '420px' }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 mb-3">
          <h1 className="autofill_warning-header mb-0">
            {t('Settings.MetaAccess.anonymous.copyLink.title')}
          </h1>
          <button
            type="button"
            onClick={() => onClose(copied)}
            className="event-editor_modal-content_btn_wrapper"
          >
            <CancelIcon width={16} />
          </button>
        </div>
        <p className="autofill_warning-msg mb-0">
          {t('Settings.MetaAccess.anonymous.copyLink.description')}
        </p>
        <p className="autofill_warning-msg">{t('Settings.MetaAccess.anonymous.copyLink.note')}</p>
        {error && <ErrorMessage danger>{t(error)}</ErrorMessage>}
        <div className="footer">
          <textarea id="auth-link" rows={1} onChange={() => {}} value={link} />
          <SecondaryButton
            onClick={copy}
            text={t('common.copy')}
            fullWidth={false}
            size="small"
            disabled={copied}
            classNames="btn-copy"
            disabledTitle=""
          >
            {copied ? (
              <div className="copy-to-clipboard">
                <div className="copy-to-clipboard__btn-wrapper">
                  <div className="icon-button">
                    <div className="copy-to-clipboard__copied">
                      <TickWrappedIcon className="copied-icon" width={14} height={14} />
                    </div>
                  </div>
                  {copied && <span>{t('common.copied')}</span>}
                </div>
              </div>
            ) : null}
          </SecondaryButton>
        </div>
      </div>
    </PortalModal>
  )
}

CopyPrompt.propTypes = {
  isOpen: PropTypes.bool,
  code: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

function ConnectByTokenType({
  tokenType,
  loggedIn,
  onFacebookLogin,
  showLogout,
  onLogout,
  onRefresh,
  loading,
  isAnonymous,
}) {
  const { t } = useTranslation()
  const [code, setCode] = useState('')

  const [promptOpen, setPromptOpen] = useState(false)
  const [fetchingCode, setFetchingCode] = useState(false)
  const { addNotification } = useToast()

  const getAnonymousAuthCode = useCallback(() => {
    setFetchingCode(true)
    authApi
      .getAnonymousAuthCode()
      .then((response) => {
        setCode(response.data.code)
        setPromptOpen(true)
      })
      .catch((error) => {
        console.error(error)
        addNotification({
          variant: 'error',
          message: t('Toasts.Meta.anonymousCodeFailed.message'),
          description: t('Toasts.Meta.anonymousCodeFailed.description'),
        })
      })
      .finally(() => {
        setFetchingCode(false)
      })
  }, [addNotification, t])

  return (
    <div className="meta_connection__buttons">
      {!isAnonymous && (
        <>
          <Button
            className="btn-sync meta_connection__buttons__item"
            variant="link"
            onClick={onRefresh}
            fullWidth={false}
            disabled={loading}
          >
            <SyncIcon className={cn('btn-sync_icon', { 'btn-sync_icon--loading': loading })} />
          </Button>
          <SecondaryButton
            onClick={getAnonymousAuthCode}
            text={t('Settings.MetaAccess.anonymous.getLink')}
            fullWidth={false}
            size="small"
            disabled={loading || fetchingCode}
            classNames="btn-copy meta_connection__buttons__item"
            disabledTitle=""
          />
          <CopyPrompt isOpen={promptOpen} code={code} onClose={() => setPromptOpen(false)} />
        </>
      )}
      <SecondaryButton
        classNames="bt bt-connect meta_connection__buttons__item"
        onClick={() =>
          showLogout && loggedIn
            ? onLogout(tokenType)
            : onFacebookLogin(
              configurations.find((x) => x.type === tokenType && !x.assetTypes.length),
              `${tokenType}state`,
            )
        }
        text={showLogout && loggedIn ? t('common.logOut') : t('Settings.MetaAccess.btnGiveAccess')}
        size="small"
        fullWidth={false}
      />
    </div>
  )
}

ConnectByTokenType.propTypes = {
  tokenType: PropTypes.oneOf([tokenTypes.user, tokenTypes.system]).isRequired,
  loggedIn: PropTypes.bool,
  onFacebookLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  showLogout: PropTypes.bool,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  isAnonymous: PropTypes.bool,
}

function ConfirmDisconnect({ open, setOpen, onLogout }) {
  const { t } = useTranslation()

  return (
    <PortalModal isOpen={open} onClickOutside={() => setOpen(false)}>
      <div className="meta meta_dialog">
        <h3 className="d-inline-block mb-3">{t('Settings.MetaAccess.disconnect.title')}</h3>
        <p>{t('Settings.MetaAccess.disconnect.body')}</p>
        <div className="d-flex justify-content-center gap-3 mt-4">
          <SecondaryButton onClick={() => setOpen(false)} text={t('common.cancel')} color="navy" />
          <SecondaryButton onClick={onLogout} text={t('common.logOut')} color="orange" />
        </div>
      </div>
    </PortalModal>
  )
}

ConfirmDisconnect.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onLogout: PropTypes.func,
}

export default function Connect({ onFacebookLogin, tokenType, showLogout = true, onLogout }) {
  const { isAnonymous, fbSessions, loading, connections, singleLoginMode } = useConnectContext()
  const { dispatch } = useConnectDispatchContext()
  const [isDisconnectConfirmDialogOpen, setIsDisconnectConfirmDialogOpen] = React.useState(false)

  const loggedIn = useMemo(() => {
    if (singleLoginMode) {
      return connections.some((connection) => connection.token_type === tokenType)
    }
    return fbSessions.some((session) => session.loggedIn && session.tokenType === tokenType)
  }, [fbSessions, connections, singleLoginMode, tokenType])

  if (!showLogout && loggedIn) {
    return null
  }

  return (
    <>
      <ConnectByTokenType
        loggedIn={loggedIn}
        tokenType={tokenType}
        onFacebookLogin={onFacebookLogin}
        showLogout={showLogout}
        onLogout={() => setIsDisconnectConfirmDialogOpen(true)}
        onRefresh={() => dispatch({ type: actions.REFRESH_STATE })}
        loading={loading}
        isAnonymous={isAnonymous}
      />
      <ConfirmDisconnect
        open={isDisconnectConfirmDialogOpen}
        setOpen={setIsDisconnectConfirmDialogOpen}
        onLogout={() => {
          setIsDisconnectConfirmDialogOpen(false)
          onLogout(tokenType)
        }}
      />
    </>
  )
}

Connect.propTypes = {
  onFacebookLogin: PropTypes.func.isRequired,
  tokenType: PropTypes.oneOf(Object.values(tokenTypes)).isRequired,
  showLogout: PropTypes.bool,
  onLogout: PropTypes.func,
}
