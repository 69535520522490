import moment from 'moment'

export const DEFAULT_EVENT_FILTERS = {
  dates: {
    from: moment(new Date()).subtract(3, 'year').format('DD-MM-YYYY'),
    to: moment().add(2, 'year').format('DD-MM-YYYY'),
    hasCustomDate: false,
  },
  locations: [],
  source: null,
  campaigns: [],
  status: { isDefault: true, value: ['DEFAULT', 'FLAGGED'] },
  search: null,
  open: false,
  cities: [],
  venuesAndHalls: [],
  includePastEvents: false,
}

export const filterReducer = (state, action) => {
  switch (action.type) {
    case 'DATES':
      return { ...state, dates: { ...state.dates, ...action.payload, option: action.payload.option, hasCustomDate: true } }
    case 'DATES_CANCEL':
      return { ...state, dates: DEFAULT_EVENT_FILTERS.dates, hasCustomDate: false }
    case 'CITIES':
      return { ...state, cities: action.payload }
    case 'VENUESANDHALLS':
      return { ...state, venuesAndHalls: action.payload }
    case 'CITIES_CANCEL':
      return { ...state, cities: DEFAULT_EVENT_FILTERS.cities }
    case 'VENUESANDHALLS_CANCEL':
      return { ...state, venuesAndHalls: DEFAULT_EVENT_FILTERS.venuesAndHalls }
    case 'LOCATIONS':
      return { ...state, locations: action.payload }
    case 'LOCATIONS_CANCEL':
      return { ...state, locations: DEFAULT_EVENT_FILTERS.locations }
    case 'STATUS':
      return { ...state, status: { isDefault: false, value: action.payload } }
    case 'STATUS_CANCEL':
      return { ...state, status: DEFAULT_EVENT_FILTERS.status }
    case 'CAMPAIGNS':
      return { ...state, campaigns: action.payload }
    case 'CAMPAIGNS_CANCEL':
      return { ...state, campaigns: DEFAULT_EVENT_FILTERS.campaigns }
    case 'SOURCE':
      return { ...state, source: action.payload }
    case 'SOURCE_CANCEL':
      return { ...state, source: DEFAULT_EVENT_FILTERS.source }
    case 'PAST_EVENTS':
      return { ...state, includePastEvents: action.payload }
    case 'SEARCH':
      return { ...state, search: action.payload }
    case 'SET_OPEN':
      return { ...state, open: action.payload }
    case 'APPLY_ALL':
      return { ...state, open: false }
    case 'CLEAR_ALL_BUT_PAST':
      return { ...DEFAULT_EVENT_FILTERS, includePastEvents: state.includePastEvents }
    case 'CLEAR_ALL':
      return { ...DEFAULT_EVENT_FILTERS }
    default:
      return { ...state }
  }
}

// Used to reset error boundary
export function mapFiltersToKeyArray(filters) {
  try {
    return [
      filters.dates.from,
      filters.dates.to,
      ...filters.locations,
      filters.source,
      filters.campaigns,
      ...filters.status.value,
      filters.search,
    ]
  } catch {
    return []
  }
}
