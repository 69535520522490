import React, { useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Accordion, Card } from 'react-bootstrap'
import { useErrorHandler } from 'react-error-boundary'

import { DEFAULT_CAMPAIGN_BUDGET, DEFAULT_CAMPAIGN_GOAL, isCampaignRunning, isCampaignOpen, hasActiveMarketingPlatformCampaign } from 'api/models'
import { arrayReducer, initStateArray } from 'reducers/default'

import InfoBox from 'components/Shared/InfoBox'
import ErrorMessage from 'components/Shared/ErrorMessage'
import EmptyView from 'components/Shared/EmptyView'

import SocialShareModal from '../../../../SocialShareModal/ModalWrapperComponent'

import RunningCampaigns from './RunningCampaigns'
import CampaignSetup from './CampaignSetup'
import classNames from 'classnames'
import useIsFirstRender from 'hooks/useFirstRender'
import { ItcStatusContextProvider } from 'components/Recommendations/Itc/ItcStatusContextProvider'
import { useCampaignSetup } from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

const Goals = ({
  event,
  setCampaigns,
  onNext,
  isFutureEvent,
  initialBudget = DEFAULT_CAMPAIGN_BUDGET,
  initialGoal = DEFAULT_CAMPAIGN_GOAL,
  fetchedCampaigns = {},
}) => {
  const { t } = useTranslation()

  const { setup, error: setupError } = useCampaignSetup()
  const { campaigns, refetchCampaigns } = fetchedCampaigns
  const isFirstRender = useIsFirstRender()

  useErrorHandler(campaigns.error ? { userFriendlyMessage: campaigns.message } : null)

  const [suggestedTcs, dispatchSuggestedTcsAction] = useReducer(arrayReducer, initStateArray)

  const [selectedTcs, setSelectedTcs] = useState([])
  const [sharedTc, setSharedTc] = useState(null)
  const [setupActiveKey, setSetupActiveKey] = useState('0')
  const [existingActiveKey, setExistingActiveKey] = useState(null)
  const [suggestedTcsChanged, setSuggestedTcsChanged] = useState(false)

  const isCampaignSetupInProgress = useMemo(() => !!setup, [setup])

  useEffect(() => {
    if (isFirstRender) refetchCampaigns()
  }, [isFirstRender, refetchCampaigns])

  useEffect(() => {
    if (!campaigns.success || !suggestedTcs.success) return

    const campaignSuggestedTcs = campaigns.items
      .filter((campaign) => suggestedTcs.items.some((tc) => tc.tc === campaign.tc) && isCampaignOpen(campaign))
      .map((campaign) => campaign.tc)

    if (!isCampaignSetupInProgress) {
      setSelectedTcs(campaignSuggestedTcs)
    } else {
      // When a setup is already in progress, restore the selected tcs from the setup process.
      setSelectedTcs(setup.creatives.map((x) => x.tc))
    }
    setCampaigns(campaigns.items)
  }, [campaigns, setCampaigns, suggestedTcs, setup, suggestedTcsChanged, isCampaignSetupInProgress])

  useEffect(() => {
    if (setup) setSelectedTcs(setup.creatives.map((x) => x.tc))
    else setSelectedTcs([])
  }, [setup])

  useEffect(() => {
    if (window.innerWidth > 992) {
      const sideDrawer = document.getElementById('side-drawer-content')

      if (!sideDrawer) {
        return
      }

      if (sharedTc) {
        sideDrawer.style.overflowY = 'hidden'
      } else {
        sideDrawer.style.overflowY = 'auto'
      }
    }
  }, [sharedTc])

  const isActive = useMemo(() => hasActiveMarketingPlatformCampaign(event) || isFutureEvent, [event, isFutureEvent])

  useEffect(() => {
    const openCampaigns = campaigns.items.filter((campaign) => isCampaignOpen(campaign))
    isFutureEvent && openCampaigns.length ? setExistingActiveKey(null) : setExistingActiveKey('1')
  }, [campaigns.items, isFutureEvent])

  if (!campaigns.success) return null

  if (campaigns.success && !campaigns.items.length)
    return <EmptyView>{t('Recommendations.emptyCampaigns', { status: '' })}</EmptyView>

  const sharedCampaign = sharedTc ? campaigns.items.find((x) => x.tc === sharedTc) : null
  const runningCampaigns = campaigns.items.filter((campaign) => isCampaignRunning(campaign))
  const openCampaigns = campaigns.items.filter((campaign) => isCampaignOpen(campaign))
  const existingCampaignsText = isActive ? 'ExistingCampaigns' : 'ExistingInactiveCampaigns'

  return (
    <div id="campaigns-container" className="mb-0">
      {isFutureEvent && openCampaigns.length ? (
        <Accordion defaultActiveKey="0">
          <Card className="goals-card">
            <Accordion.Toggle eventKey="0">
              <Card.Header
                as={Card.Header}
                className={classNames({
                  'goals-card_header pt-3': true,
                  open: setupActiveKey === '0',
                })}
                onClick={() => setSetupActiveKey(setupActiveKey && setupActiveKey === '0' ? null : '0')}
              >
                <InfoBox
                  title={t('Tips.Recommendations.CampaignSetup.title')}
                  body={t('Tips.Recommendations.CampaignSetup.body')}
                  classNames="whitespace"
                >
                  {t('Tips.Recommendations.CampaignSetup.title')}
                </InfoBox>
                <div className="align-self-center ml-auto">
                  <div className={`accordion-arrow ${setupActiveKey === '0' ? 'open' : ''}`} />
                </div>
              </Card.Header>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ItcStatusContextProvider eventId={event.id}>
                  <CampaignSetup
                    event={event}
                    campaigns={campaigns}
                    suggestedTcs={suggestedTcs}
                    selectedTcs={selectedTcs}
                    setSelectedTcs={setSelectedTcs}
                    initialBudget={initialBudget}
                    initialGoal={initialGoal}
                    dispatchSuggestedTcsAction={dispatchSuggestedTcsAction}
                    openCampaigns={openCampaigns}
                    onNext={onNext}
                    setSuggestedTcsChanged={setSuggestedTcsChanged}
                    refetchCampaigns={refetchCampaigns}
                  />
                </ItcStatusContextProvider>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : null}
      {runningCampaigns.length ? (
        <Accordion activeKey={existingActiveKey} defaultActiveKey={null}>
          <Card className="goals-card">
            <Accordion.Toggle eventKey="1">
              <Card.Header
                as={Card.Header}
                className={classNames({
                  'goals-card_header pt-3': true,
                  open: existingActiveKey === '1',
                })}
                onClick={() => setExistingActiveKey(existingActiveKey && existingActiveKey === '1' ? null : '1')}
              >
                <InfoBox
                  title={t(`Tips.Recommendations.${existingCampaignsText}.title`)}
                  body={t(`Tips.Recommendations.${existingCampaignsText}.body`)}
                  classNames="whitespace"
                >
                  {t(`Tips.Recommendations.${existingCampaignsText}.title`)}
                </InfoBox>
                <div className="align-self-center ml-auto">
                  <div className={`accordion-arrow ${existingActiveKey === '1' ? 'open' : ''}`} />
                </div>
              </Card.Header>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="d-flex flex-column py-3">
                <RunningCampaigns
                  event={event}
                  campaigns={runningCampaigns}
                  setSharedTc={setSharedTc}
                  initialGoal={initialGoal}
                  refetchCampaigns={refetchCampaigns}
                  isFutureEvent={isActive}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : null}

      {setupError && <ErrorMessage danger>{t(setupError)}</ErrorMessage>}

      {/* when boost sales has been clicked = show a modal for reviewing changes and sharing post */}
      {sharedCampaign && (
        <SocialShareModal
          eventId={event.id}
          shareUrls={sharedCampaign.latest_campaign_setup.creative.destination_url}
          description={sharedCampaign.latest_campaign_setup.creative.body[0]}
          headline={sharedCampaign.latest_campaign_setup.creative.headline[0]}
          closeModal={() => setSharedTc(null)}
          isModalOpen={sharedTc}
        />
      )}
    </div>
  )
}

Goals.propTypes = {
  event: PropTypes.object.isRequired,
  setCampaigns: PropTypes.func.isRequired,
  initialBudget: PropTypes.number,
  initialGoal: PropTypes.string,
  onNext: PropTypes.func,
  isFutureEvent: PropTypes.bool,
  fetchedCampaigns: PropTypes.object,
}

export default Goals
