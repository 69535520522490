import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import useIsFirstRender from 'hooks/useFirstRender'
import metaAuthorizationApi from 'api/connect/meta'
import { MetaAccessContent } from 'components/Settings/Meta'
import PrimaryButton from 'components/Shared/Button'
import { useTranslation } from 'react-i18next'
import { ConnectContextProvider, useConnectContext } from 'components/Settings/Meta/connect'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'components/Shared/ErrorFallback'
import ErrorMessage from 'components/Shared/ErrorMessage'

export function ThirdPartyFBLoginContent({ match }) {
  const { language } = match.params
  const searchParams = new URLSearchParams(window.location.search)
  const redirectUriParam = searchParams.get('redirect_uri')
  const [accessToken, setAccessToken] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { connections } = useConnectContext()
  const firstRender = useIsFirstRender()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    // set language
    if (!firstRender) return

    if (language && ['en', 'de'].includes(language)) {
      i18n.changeLanguage(language)
    }
  }, [firstRender, i18n, language])

  useEffect(() => {
    // Exchange auth_code for access token, and set a partner session
    if (!firstRender) return

    const searchParams = new URLSearchParams(window.location.search)
    const authCodeParam = searchParams.get('code')

    if (!authCodeParam || !redirectUriParam) {
      // set error code
      setError('fd.error.missingAuthCodeOrRedirectUri')
    } else {
      // Call API to exchange auth_code for access token
      // This is a simplified example; replace with your actual API call
      setLoading(true)
      metaAuthorizationApi
        .getPartnerToken(authCodeParam, redirectUriParam)
        .then((response) => {
          setLoading(false)
          const { data, error } = response
          if (error) {
            console.error(error)
            setError('fd.error.invalidAuthCode')
            return
          }
          // save the access token in session storage
          sessionStorage.setItem('partner-session', JSON.stringify(data))
          setAccessToken(data.access_token)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
          setError('fd.error.invalidAuthCode')
        })
    }
  }, [firstRender, redirectUriParam])

  const onReturn = (status = true) => {
    // if this is open in a popup, post a message to the parent window, and close the popup
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'fd-msg',
          subtype: 'meta-access',
          success: !!status,
          error,
        },
        '*',
      )
      window.close()
    } else {
      // otherwise, remove the partnerSession, clean the history, navigate back to the original redirect_uri
      sessionStorage.removeItem('partner-session')

      const url = new URL(redirectUriParam)
      if (error) {
        url.searchParams.set('error', error)
      }
      window.history.replaceState({}, '', url)
    }
  }

  const redirectUri = useMemo(() => {
    if (!redirectUriParam || window.opener) return null
    const url = new URL(redirectUriParam)
    if (error) {
      url.searchParams.set('error', error)
    }
    return url.toString()
  }, [error, redirectUriParam])

  const connected = useMemo(() => connections.length > 0, [connections])

  return (
    <Layout title="Facebook Login">
      <Helmet bodyAttributes={{ class: '' }} />
      <div>
        <main className="third-party-fb-login pt-5">
          <div className="container-fluid h-100">
            <div className="d-flex flex-column align-items-center justify-content-center h-100">
              {error && (
                <div>
                  <ErrorMessage danger>{t('common.genericError')}</ErrorMessage>
                  <button onClick={() => onReturn(false)}>{t('Settings.MetaAccess.btnCloseAndReturn')}</button>
                </div>
              )}
              {loading && <LoadingSpinner showText={false} />}
              {accessToken && (
                <div className="col-12 col-lg-9 settings-page__container">
                  <div className="meta card card-default settings-page__card">
                    <MetaAccessContent />
                  </div>
                  {connected && (
                    <div className="d-flex align-items-center justify-content-center my-4">
                      <PrimaryButton
                        classNames="bt bt-toolbar px-4"
                        onClick={onReturn}
                        linkTo={redirectUri}
                        text={t('Settings.MetaAccess.btnSaveAndReturn')}
                        fullWidth
                        color="navy"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

ThirdPartyFBLoginContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      language: PropTypes.string,
    }),
  }),
}

export function ThirdPartyFBLogin({ match }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ConnectContextProvider singleLoginMode>
        <ThirdPartyFBLoginContent match={match} />
      </ConnectContextProvider>
    </ErrorBoundary>
  )
}

ThirdPartyFBLogin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      language: PropTypes.string,
    }),
  }),
}
