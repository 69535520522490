import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { actions, isChangeCampaignStatusRunning } from './campaignStatusReducer'

import CampaignActivationModal from 'components/Recommendations/CampaignStatusModals/CampaignActivationModal'
import CampaignOptimizationModal from 'components/Recommendations/CampaignStatusModals/CampaignOptimizationModal'
import Switch from 'components/Shared/Controls/Switch'
import PortalModal from 'components/Shared/PortalModal'

import { RemoveIcon } from 'images'
import { useCampaignMenu, useCampaignMenuDispatch } from './CampaignMenuContext'
import {
  useAttributionModel,
  useAttributionModelDispatch,
} from 'domain/events/attributionModel/EventAttributionModelContextProvider'
import {
  ATTRIBUTION_MODELS,
  actions as attribtionModelActions,
} from 'domain/events/attributionModel/eventAttributionModelReducer'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'
import EventHeaderMenuWrapper from 'components/Event/Details/EventHeader/EventHeaderMenuWrapper'
import { updateEventStatus } from 'api/events'
import ArchivePrompt from 'components/Recommendations/CampaignStatusModals/ArchivePrompt'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ButtonItem, SeparatorItem, SwitchItem } from 'components/Shared/Menu'
import useCustomTranslation from 'hooks/useCustomTranslation'

export default function CampaignMenu({
  event,
  isMobile,
  openMobileList,
  setOpenMobileList,
  isWave = false,
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const hasPrismaAccess = hasPrismaAccessTier()
  const [isOptimizationModalOpen, setIsOptimizationModalOpen] = useState(false)
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false)
  const [frontendStatus, setFrontendStatus] = useState(event.frontend_status)
  const [isArchivePromptOpen, setIsArchivePromptOpen] = useState(false)
  const { getTranslation } = useCustomTranslation(event)
  const isCategoryProduct = event.category === 'product'

  const state = useCampaignMenu()
  const statusDispatch = useCampaignMenuDispatch()
  const {
    hasMarketingCampaign,
    hasActiveCampaign,
    hasEndedCampaign,
    isCampaignOptimizationOn,
  } = state

  const { attributionModel } = useAttributionModel()
  const dispatchAttributionModelAction = useAttributionModelDispatch()

  const handleAttributionModelChange = (attributionModel) => {
    dispatchAttributionModelAction({
      type: attribtionModelActions.updateEventAttributionModelAsync,
      payload: { eventId: event.id, attributionModel },
    })
  }

  useEffect(() => {
    if (event && !frontendStatus && event.frontend_status !== frontendStatus) {
      setFrontendStatus(event.frontend_status)
    }
  }, [event, frontendStatus])

  const archiveEvent = useCallback(() => {
    updateEventStatus(event.id, 'HIDDEN')
      .then((result) => {
        if (result.success) {
          setFrontendStatus('HIDDEN')
          setIsArchivePromptOpen(false)
          history.push(location.pathname)
        } else {
          console.error('Failed to update frontent status')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [event.id, history, location.pathname])

  if (!event) {
    return null
  }

  const menuOptions = [
    {
      title: getTranslation('Event.edit'),
      onClick: () => {
        history.replace({
          pathname:
            isWave && isCategoryProduct
              ? `/campaigns/edit/${event.id}`
              : `/events/edit/${event.id}`,
          state: isWave && isCategoryProduct ? { from: 'wave' } : {},
        })
      },
      optionType: 'button',
    },
  ]

  if (hasMarketingCampaign) {
    const extraOptions = {
      title: isChangeCampaignStatusRunning(state)
        ? t('Recommendations.campaignOptions.statuses.pending')
        : hasEndedCampaign
          ? t('Recommendations.campaignOptions.statuses.inactive')
          : hasActiveCampaign
            ? t('Recommendations.campaignOptions.statuses.active')
            : t('Recommendations.campaignOptions.statuses.paused'),
      optionType: 'expandableItem',
      entries: [
        {
          title: t('Recommendations.campaignOptions.activateCampaign'),
          optionType: 'switch',
          onClick: () => setIsActivationModalOpen(true),
          checked: hasActiveCampaign,
          disabled: hasEndedCampaign || isChangeCampaignStatusRunning(state),
        },
      ],
    }

    if (hasActiveCampaign && !isChangeCampaignStatusRunning(state)) {
      extraOptions.entries.push({
        title: t('Recommendations.campaignOptions.optimizeCampaign'),
        optionType: 'switch',
        onClick: () => setIsOptimizationModalOpen(true),
        checked: isCampaignOptimizationOn,
      })
    }

    menuOptions.push(extraOptions)
  }
  if (isWave) {
    const attribtionOptions = [
      {
        title: t('Settings.system.attributionModel.options.meta'),
        onClick: () => handleAttributionModelChange(ATTRIBUTION_MODELS.meta),
        optionType: 'radio',
        checked: isCategoryProduct
          ? attributionModel === ATTRIBUTION_MODELS.meta || ATTRIBUTION_MODELS.fd
          : attributionModel === ATTRIBUTION_MODELS.meta,
        name: 'attribution-model',
        id: 'attribution-model-meta',
      },
      {
        title: t('Settings.system.attributionModel.options.external'),
        onClick: () => handleAttributionModelChange(ATTRIBUTION_MODELS.external),
        optionType: 'radio',
        checked: attributionModel === ATTRIBUTION_MODELS.external,
        name: 'attribution-model',
        id: 'attribution-model-external',
      },
    ]

    if (!hasPrismaAccess && !isCategoryProduct) {
      attribtionOptions.unshift({
        title: t('Settings.system.attributionModel.options.fd'),
        onClick: () => handleAttributionModelChange(ATTRIBUTION_MODELS.fd),
        optionType: 'radio',
        checked: attributionModel === ATTRIBUTION_MODELS.fd,
        name: 'attribution-model',
        id: 'attribution-model-fd',
      })
    }

    menuOptions.push({
      title: t('Settings.system.attributionModel.title'),
      optionType: 'expandableItem',
      entries: attribtionOptions,
    })

    if (event.frontend_status !== 'HIDDEN') {
      menuOptions.push({
        title: t('Recommendations.campaignOptions.eventFlagged'),
        onClick: () => {
          const newStatus = frontendStatus === 'FLAGGED' ? 'DEFAULT' : 'FLAGGED'
          updateEventStatus(event.id, newStatus)
            .then((result) => {
              if (result.success) {
                setFrontendStatus(newStatus)
              } else {
                console.error('Failed to update frontent status')
              }
            })
            .catch((error) => {
              console.error(error)
            })
        },
        optionType: 'switch',
        checked: frontendStatus === 'FLAGGED',
      })
    }

    if (event.frontend_status !== 'HIDDEN') {
      menuOptions.push({ optionType: 'separator' })
      menuOptions.push({
        title: t('Recommendations.campaignOptions.eventArchive'),
        onClick: () => setIsArchivePromptOpen(true),
        optionType: 'button',
        variant: 'danger',
      })
    }
  }

  if (menuOptions.length === 0 || (isMobile && !openMobileList)) {
    return null
  }

  return (
    <>
      {isMobile ? (
        <div>
          <div className="backdrop" onClick={() => setOpenMobileList(false)} />
          <div className="dropdown-list">
            <span className="close-mobile" onClick={() => setOpenMobileList(false)}>
              <RemoveIcon width={35} height={35} />
            </span>
            <div
              className="dots-menu_each-item_wrapper"
              onClick={() => history.replace(`/events/edit/${event.id}`)}
            >
              <div className="dots-menu_each-item_title">{t('Event.edit')}</div>
            </div>
            {hasMarketingCampaign && (
              <>
                <div className="dots-menu_each-item_wrapper border-bottom no-hover">
                  <div className="dots-menu_each-item_title no-icon">
                    {hasEndedCampaign
                      ? t('Recommendations.campaignOptions.statuses.inactive')
                      : hasActiveCampaign
                        ? t('Recommendations.campaignOptions.statuses.active')
                        : t('Recommendations.campaignOptions.statuses.paused')}
                  </div>
                </div>
                <div className="dots-menu_each-item_wrapper">
                  <Switch
                    label={t('Recommendations.campaignOptions.activateCampaign')}
                    onClick={() => setIsActivationModalOpen(true)}
                    checked={hasActiveCampaign}
                    disabled={hasEndedCampaign}
                  />
                </div>
                {hasActiveCampaign ? (
                  <div className="dots-menu_each-item_wrapper">
                    <Switch
                      label={t('Recommendations.campaignOptions.optimizeCampaign')}
                      onClick={() => setIsOptimizationModalOpen(true)}
                      checked={isCampaignOptimizationOn}
                    />
                  </div>
                ) : null}
              </>
            )}
            <SwitchItem
              item={{
                title: t('Recommendations.campaignOptions.eventFlagged'),
                onClick: () => {
                  const newStatus = frontendStatus === 'FLAGGED' ? 'DEFAULT' : 'FLAGGED'
                  updateEventStatus(event.id, newStatus)
                    .then((result) => {
                      if (result.success) {
                        setFrontendStatus(newStatus)
                      } else {
                        console.error('Failed to update frontent status')
                      }
                    })
                    .catch((error) => {
                      console.error(error)
                    })
                },
                optionType: 'switch',
                checked: frontendStatus === 'FLAGGED',
              }}
            />
            <SeparatorItem />
            <ButtonItem
              item={{
                title: t('Recommendations.campaignOptions.eventArchive'),
                onClick: () => setIsArchivePromptOpen(true),
                optionType: 'button',
                variant: 'danger',
              }}
            />
          </div>
        </div>
      ) : (
        <EventHeaderMenuWrapper options={menuOptions} />
      )}
      <PortalModal
        isOpen={isActivationModalOpen}
        onClickOutside={() => setIsActivationModalOpen(false)}
      >
        <CampaignActivationModal
          status={hasActiveCampaign ? 'pause' : 'activate'}
          onCancel={() => setIsActivationModalOpen(false)}
          onConfirm={() => {
            setIsActivationModalOpen(false)
            statusDispatch({ type: actions.toggleCampaignStatusAsync, payload: { event } })
          }}
        />
      </PortalModal>
      <PortalModal
        isOpen={isOptimizationModalOpen}
        onClickOutside={() => setIsOptimizationModalOpen(false)}
      >
        <CampaignOptimizationModal
          status={isCampaignOptimizationOn ? 'deactivate' : 'activate'}
          onCancel={() => setIsOptimizationModalOpen(false)}
          onConfirm={() => {
            setIsOptimizationModalOpen(false)
            statusDispatch({ type: actions.toggleCampaignOptimizationAsync, payload: { event } })
          }}
        />
      </PortalModal>
      <PortalModal
        isOpen={isArchivePromptOpen && event.title}
        onClickOutside={() => setIsArchivePromptOpen(false)}
      >
        <ArchivePrompt
          eventTitle={event.title}
          onCancel={() => setIsArchivePromptOpen(false)}
          onConfirm={archiveEvent}
        />
      </PortalModal>
    </>
  )
}

CampaignMenu.propTypes = {
  event: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  openMobileList: PropTypes.bool,
  setOpenMobileList: PropTypes.func,
  isWave: PropTypes.bool,
}
