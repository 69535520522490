import React, { useEffect, useState, useReducer, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import cn from 'classnames'

import { fetchConversionGoals } from 'api/integrations'
import { fetchCustomEventTypes } from 'api/campaigns'
import { initStateArray, arrayReducer, OVERWRITE } from 'reducers/default'
import { useOutsideCheckClick } from 'hooks/UseOnClickOutside'
import { AssetClass, CampaignGoal, Platform } from 'api/models'

import SearchDropdown from './Edit/SearchDropdown'
import { getErrorText } from './Edit/adAccountHelper'
import { useCampaignSetupDispatch, useCampaignSetup } from './Context/CampaignSetupContext'

import InfoBox from 'components/Shared/InfoBox'
import TipDialogueBox from 'components/Shared/TipDialogueBox'
import LoadingSpinner, { SpinnerSize } from 'components/Shared/LoadingSpinner'

import { RemoveIcon } from 'images'
import {
  getAvailableInstagramAccounts,
  getAvailablePages,
  getAvailablePixels,
} from './Context/util'

function PlatformSelectMenu({
  handleClick,
  integrations,
  required = false,
  subHeadlineText,
  removeInteg,
  selectedIntegration,
  selectText,
  disabled,
}) {
  const autoSelected = integrations.length === 1 && required
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const menuRef = useRef(null)

  useOutsideCheckClick(menuRef, setOpen, open)

  const handleMenuClick = () => {
    !disabled && setOpen(!open)
  }

  const selectedIntegrationInvalid =
    !!selectedIntegration?.asset_id &&
    integrations.find((x) => x.asset_id === selectedIntegration.asset_id)?.valid !== true

  return (
    <div className="select-ad-platform_select-menu">
      {!!subHeadlineText && (
        <strong className="select-ad-platform_select-menu_select-label">{subHeadlineText}</strong>
      )}
      <div
        onClick={handleMenuClick}
        ref={menuRef}
        className={cn({ 'select-ad-platform_select-menu_wrapper': true, open })}
      >
        <div
          className={cn('select-ad-platform_select-menu_input', {
            open,
            selected: autoSelected || selectedIntegration?.id ? 'selected' : '',
            disabled,
            invalid: selectedIntegrationInvalid,
          })}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center">
              <div
                className="selected-value"
                style={{ color: autoSelected ? '#3b3b3b' : '#5C5C5C' }}
              >
                {selectedIntegration
                  ? `${selectedIntegration.asset_label} (ID: ${selectedIntegration.asset_id})`
                  : selectText}
              </div>
            </div>

            {!required && selectedIntegration?.id && !disabled && (
              <div className="remove">
                <RemoveIcon width={14} onClick={(e) => removeInteg(e, selectedIntegration.id)} />
              </div>
            )}
          </div>
          {!disabled && (
            <div
              style={{
                transitionDuration: '0.2s',
                marginLeft: !autoSelected && integrations.length > 1 ? 'unset' : 'auto',
                transform: open ? 'rotate(180deg)' : 'rotate(0)',
              }}
              className={cn({ 'events-insights-page__status_menu_arrow': true, open })}
            />
          )}
        </div>
        <div className={cn({ 'select-ad-platform_select-menu_drop-menu_wrapper': true, open })}>
          {/* we have auto selected the only choice available and there are no others remaining */}
          {integrations.length === 1 && selectedIntegration?.id && (
            <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
              <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>
                {t('common.noMoreFields')}
              </div>
            </div>
          )}

          {/* there are no other choices remaining - show an empty select list */}
          {integrations.length === 0 && (
            <div className="select-ad-platform_select-menu_drop-menu_row_wrapper">
              <div style={{ color: '#5C5C5C', width: '100%', textAlign: 'center' }}>
                {t('common.noMoreFields')}
              </div>
            </div>
          )}

          {/* we have many integration choices available - allow the user to choose from a list */}
          {integrations &&
            integrations.length > 0 &&
            integrations.map((eachIntegration, index) => {
              if (selectedIntegration?.id === eachIntegration.id) return null
              return (
                <div
                  className={cn('select-ad-platform_select-menu_drop-menu_row_wrapper', {
                    invalid: !eachIntegration.valid,
                  })}
                  key={index}
                  onClick={(e) => {
                    if (!eachIntegration.valid) return
                    handleClick(e, eachIntegration.id)
                    setOpen(false)
                  }}
                >
                  <div>
                    {eachIntegration.asset_label} (ID: {eachIntegration.asset_id})
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      {selectedIntegrationInvalid && (
        <p className="warning-red mt-2">
          <Trans
            i18nKey="Recommendations.campaign.invalidAccount"
            components={{
              a: (
                <a
                  href="/settings#meta"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="d-inline-block"
                  style={{ color: '#f56666', textDecoration: 'underline' }}
                >
                  Meta assets page
                </a>
              ),
            }}
            values={{
              accountName: selectedIntegration.asset_label,
              accountId: selectedIntegration.asset_id,
            }}
          >
            Permission missing to access {selectedIntegration.asset_label} (ID:{' '}
            {selectedIntegration.asset_id}). To enable it for use, please reconnect the asset on{' '}
            <a href="/settings#meta">Meta asset page</a> and grant us the necessary access.
          </Trans>
        </p>
      )}
    </div>
  )
}

PlatformSelectMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  integrations: PropTypes.array.isRequired,
  required: PropTypes.bool,
  subHeadlineText: PropTypes.string,
  removeInteg: PropTypes.func,
  selectedIntegration: PropTypes.object,
  selectText: PropTypes.string,
  disabled: PropTypes.bool,
}

const SelectAdPlatformForm = ({ isDisabled }) => {
  const { t } = useTranslation()

  const { setup, accounts: partnerAccounts, metaConnectionStatus } = useCampaignSetup()
  const { integration_details: currentIntegrations, goal, conversion_id, custom_event_type } = setup
  const { enrichAccountsAndReconfigure, fetchAccounts } = useCampaignSetupDispatch()

  const [selectedIntegrations, dispatchSelectedIntegrationsAction] = useReducer(arrayReducer, {
    ...initStateArray,
    items: currentIntegrations,
  })
  const [conversionGoals, dispatchConversionGoalsAction] = useReducer(arrayReducer, initStateArray)
  const [customEventTypes, dispatchCustomEventTypesAction] = useReducer(
    arrayReducer,
    initStateArray,
  )

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  const selectedAdAccountId = useMemo(() => {
    return selectedIntegrations.items.find((x) => x.asset_class === AssetClass.ad_account)?.asset_id
  }, [selectedIntegrations])

  useEffect(() => {
    fetchCustomEventTypes(dispatchCustomEventTypesAction)
  }, [])

  const retrieveConversionGoals = useCallback(() => {
    if (selectedAdAccountId)
      fetchConversionGoals(dispatchConversionGoalsAction, selectedAdAccountId)
  }, [selectedAdAccountId])

  useEffect(() => {
    retrieveConversionGoals()
  }, [retrieveConversionGoals])

  useEffect(() => {
    // select a default ad account and profiles
    const { adAccount, page, instagramAccount, pixel } = getDefaultIntegrations(
      partnerAccounts,
      selectedIntegrations.items,
      metaConnectionStatus,
    )
    const newIntegrations = [adAccount, page, instagramAccount, pixel].filter((x) => x)

    if (
      newIntegrations.length &&
      // check if the new integrations are different from the current integrations
      !newIntegrations
        .map((x) => x.id)
        .every((pid) => selectedIntegrations.items.map((x) => x.id).includes(pid))
    ) {
      dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: newIntegrations })
      enrichAccountsAndReconfigure({ ...setup, integration_details: newIntegrations })
    }
  }, [
    partnerAccounts,
    setup,
    enrichAccountsAndReconfigure,
    selectedIntegrations.items,
    metaConnectionStatus,
  ])

  const onIntegrationClicked = (id) => {
    const integration = partnerAccounts.find((x) => x.id === id)
    if (!integration?.asset_class)
      throw new Error(
        `Selected integration id was invalid or, or the corresponding integration does not have a valid asset_class: ${id}`,
      )

    let newIntegrations = []

    if (!metaConnectionStatus) {
      // Partner is not connected to Meta, so we don't enforce asset relationship validation.
      newIntegrations = [
        ...selectedIntegrations.items.filter(
          (x) => x.asset_class !== integration.asset_class || x.platform !== integration.platform,
        ),
        integration,
      ]
    } else {
      newIntegrations = updateSelectedIntegrations(
        integration,
        partnerAccounts,
        selectedIntegrations.items,
        newIntegrations,
        metaConnectionStatus,
      )
    }

    dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: newIntegrations })
    enrichAccountsAndReconfigure({ ...setup, integration_details: newIntegrations })
  }

  const onIntegrationRemoved = (e, id) => {
    e.preventDefault()
    e.stopPropagation()

    const newIntegrations = selectedIntegrations.items.filter((x) => x.id !== id)

    dispatchSelectedIntegrationsAction({ type: OVERWRITE, payload: newIntegrations })
    enrichAccountsAndReconfigure({ ...setup, integration_details: newIntegrations })
  }

  const isPixel =
    (selectedIntegrations.items || []).some((el) => el.asset_class === AssetClass.pixel) &&
    goal !== CampaignGoal.visibility

  const onConversionGoalChanged = useCallback(
    (conversionId) => {
      enrichAccountsAndReconfigure({
        ...setup,
        conversion_id: conversionId,
        custom_event_type: '',
      })
    },
    [setup, enrichAccountsAndReconfigure],
  )

  const onCustomEventTypeChanged = useCallback(
    (customEventType) => {
      enrichAccountsAndReconfigure({
        ...setup,
        custom_event_type: customEventType,
        conversion_id: '',
      })
    },
    [setup, enrichAccountsAndReconfigure],
  )

  const conversionValue = useMemo(() => {
    const conversion = conversionGoals.items.find((el) => el.id === conversion_id)
    if (conversion) return conversion
    if (!custom_event_type) return null

    return {
      id: custom_event_type,
      name: t(`Recommendations.campaign.customEventTypes.${custom_event_type}`),
      type: 'custom_event',
    }
  }, [conversion_id, custom_event_type, conversionGoals.items, t])

  const conversionOptions = useMemo(() => {
    return customEventTypes.items
      .map((x) => ({
        id: x,
        name: t(`Recommendations.campaign.customEventTypes.${x}`),
        type: 'custom_event',
        default: x === 'PURCHASE',
      }))
      .concat(conversionGoals.items)
  }, [conversionGoals.items, customEventTypes.items, t])

  const selectedAdAccount = useMemo(
    () => selectedIntegrations.items.find((x) => x.asset_class === AssetClass.ad_account),
    [selectedIntegrations],
  )

  const selectedPage = useMemo(
    () =>
      selectedIntegrations.items.find(
        (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
      ),
    [selectedIntegrations],
  )

  return (
    <div>
      {partnerAccounts.loading ? (
        <LoadingSpinner size={SpinnerSize.LARGE} />
      ) : (
        <form className="select-ad-platform_wrapper">
          <TipDialogueBox
            title={'Social media'}
            text={
              <Trans
                i18nKey="Recommendations.addSocialTextTDB"
                components={{
                  a: (
                    <a
                      href="/settings#meta"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: 'inline-block', color: '#2F80ED' }}
                    >
                      Meta assets page
                    </a>
                  ),
                }}
              />
            }
          />
          {partnerAccounts.error && <p className="warning-red">{partnerAccounts.message}</p>}
          <div className="select-ad-platform_section">
            <InfoBox
              content="Tips.Recommendations.SelectPlatform"
              placement="bottom"
              classNames="display-flex whitespace"
            >
              <h5 className="select-ad-platform_section_title">
                {t('Recommendations.socialPublishAccountTitle')}
              </h5>
            </InfoBox>
            <PlatformSelectMenu
              handleClick={(_, id) => onIntegrationClicked(id)}
              removeInteg={(e, id) => onIntegrationRemoved(e, id)}
              integrations={getAvailablePages(
                partnerAccounts,
                selectedAdAccount,
                metaConnectionStatus,
              )}
              subHeadlineText={'Facebook / Meta'}
              selectedIntegration={selectedIntegrations.items.find(
                (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
              )}
              selectText={t('Recommendations.campaign.selectPubAccount')}
              required
            />
            <div className="mt-4">
              <PlatformSelectMenu
                handleClick={(_, id) => onIntegrationClicked(id)}
                removeInteg={(e, id) => onIntegrationRemoved(e, id)}
                integrations={getAvailableInstagramAccounts(
                  partnerAccounts,
                  selectedPage,
                  metaConnectionStatus,
                )}
                subHeadlineText={'Instagram'}
                selectedIntegration={selectedIntegrations.items.find(
                  (x) => x.asset_class === AssetClass.profile && x.platform === Platform.instagram,
                )}
                selectText={t('Recommendations.campaign.selectPubAccount')}
              />
            </div>
          </div>

          <div className="select-ad-platform_section">
            <h5 className="select-ad-platform_section_title">
              {t('Recommendations.socialAdvertAccountTitle')}
              <span className="warning-red">*</span>
            </h5>
            <PlatformSelectMenu
              handleClick={(_, id) => onIntegrationClicked(id)}
              removeInteg={(e, id) => onIntegrationRemoved(e, id)}
              integrations={partnerAccounts.filter((x) => x.asset_class === AssetClass.ad_account)}
              subHeadlineText={null}
              required
              selectedIntegration={selectedIntegrations.items.find(
                (x) => x.asset_class === AssetClass.ad_account,
              )}
              selectText={t('Recommendations.campaign.selectAdAccount')}
              disabled={isDisabled}
            />
          </div>

          <div className="select-ad-platform_section">
            <h5 className="select-ad-platform_section_title">{t('Recommendations.datasets')}</h5>
            <PlatformSelectMenu
              handleClick={(_, id) => onIntegrationClicked(id)}
              removeInteg={(e, id) => onIntegrationRemoved(e, id)}
              integrations={getAvailablePixels(
                partnerAccounts,
                selectedAdAccount,
                metaConnectionStatus,
              )}
              subHeadlineText={null}
              selectedIntegration={selectedIntegrations.items.find(
                (x) => x.asset_class === AssetClass.pixel,
              )}
              selectText={t('Recommendations.campaign.selectPixelAccount')}
              required={false}
              disabled={isDisabled}
            />

            {isPixel && (
              <div className="mt-3">
                <InfoBox content="Tips.Recommendations.ConversionGoal">
                  <label className="select-ad-platform_select-menu_select-label d-inline-block mb-3">
                    {t('Tips.Recommendations.ConversionGoal.title')}
                  </label>
                </InfoBox>
                <SearchDropdown
                  placeholder={t('Recommendations.campaign.searchConversionGoals')}
                  options={conversionOptions}
                  value={conversionValue}
                  onChange={(item) => {
                    if (item.type === 'custom_event') {
                      onCustomEventTypeChanged(item.id)
                    } else {
                      onConversionGoalChanged(item?.id)
                    }
                  }}
                  removeSelectedItem={() => onConversionGoalChanged('')}
                  onErrorBtnClick={retrieveConversionGoals}
                  error={conversionGoals.error ? getErrorText(conversionGoals.message) : null}
                  loading={conversionGoals.loading}
                  disabled={isDisabled}
                />
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  )
}

SelectAdPlatformForm.propTypes = {
  isDisabled: PropTypes.bool,
}

function updateSelectedIntegrations(
  selectedIntegration,
  partnerAccounts,
  selectedIntegrations,
  metaConnectionStatus,
) {
  let newIntegrations = [
    ...selectedIntegrations.filter(
      (x) =>
        x.asset_class !== selectedIntegration.asset_class ||
        x.platform !== selectedIntegration.platform,
    ),
    selectedIntegration,
  ]

  const selectedAdAccount = newIntegrations.find((x) => x.asset_class === AssetClass.ad_account)

  let selectedPage = newIntegrations.find(
    (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
  )
  if (selectedPage) {
    const availablePages = getAvailablePages(
      partnerAccounts,
      selectedAdAccount,
      metaConnectionStatus,
    )
    if (!availablePages.some((x) => x.asset_id === selectedPage.asset_id)) {
      selectedPage = null
      newIntegrations = newIntegrations.filter(
        (x) => x.asset_class !== AssetClass.profile || x.platform !== Platform.facebook,
      )
      if (availablePages.length > 0) {
        selectedPage = availablePages[0]
        newIntegrations.push(selectedPage)
      }
    }
  }

  const selectedPixel = newIntegrations.find((x) => x.asset_class === AssetClass.pixel)
  if (selectedPixel) {
    const availablePixels = getAvailablePixels(
      partnerAccounts,
      selectedAdAccount,
      metaConnectionStatus,
    )
    if (!availablePixels.some((x) => x.asset_id === selectedPixel.asset_id)) {
      newIntegrations = newIntegrations.filter((x) => x.asset_class !== AssetClass.pixel)
      if (availablePixels.length > 0) {
        newIntegrations.push(availablePixels[0])
      }
    }
  }

  const selectedInstagram = newIntegrations.find(
    (x) => x.asset_class === AssetClass.profile && x.platform === Platform.instagram,
  )
  if (selectedInstagram) {
    const availableInstagram = getAvailableInstagramAccounts(
      partnerAccounts,
      selectedPage,
      metaConnectionStatus,
    )
    if (!availableInstagram.some((x) => x.asset_id === selectedInstagram.asset_id)) {
      newIntegrations = newIntegrations.filter(
        (x) => x.asset_class !== AssetClass.profile || x.platform !== Platform.instagram,
      )
      if (availableInstagram.length > 0) {
        newIntegrations.push(availableInstagram[0])
      }
    }
  }

  return newIntegrations
}

function getDefaultIntegrations(accounts, selectedAccounts, metaConnectionStatus) {
  if (!metaConnectionStatus) {
    // Don't enforce asset relationship validation for clients without meta connection.
    let adAccount = selectedAccounts.find((x) => x.asset_class === AssetClass.ad_account)
    if (!adAccount) {
      adAccount = accounts.find((x) => x.asset_class === AssetClass.ad_account && x.is_active)
    }

    let page = selectedAccounts.find(
      (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
    )
    if (!page) {
      page = accounts.find(
        (x) =>
          x.asset_class === AssetClass.profile && x.platform === Platform.facebook && x.is_active,
      )
    }

    const selectedInstagram = selectedAccounts.find(
      (x) => x.asset_class === AssetClass.profile && x.platform === Platform.instagram,
    )
    const selectedPixel = selectedAccounts.find((x) => x.asset_class === AssetClass.pixel)

    return { adAccount, page, instagramAccount: selectedInstagram, pixel: selectedPixel }
  }

  const selectedAdAccount =
    selectedAccounts.find((x) => x.asset_class === AssetClass.ad_account) ??
    accounts.find((x) => x.asset_class === AssetClass.ad_account && x.is_active)
  const availablePages = getAvailablePages(accounts, selectedAdAccount, metaConnectionStatus)
  let selectedPage = selectedAccounts.find(
    (x) => x.asset_class === AssetClass.profile && x.platform === Platform.facebook,
  )

  if (availablePages.length > 0 && !availablePages.some((x) => x.id === selectedPage?.id)) {
    selectedPage = availablePages[0]
  } else {
    selectedPage = null
  }

  let selectedPixel = selectedAccounts.find((x) => x.asset_class === AssetClass.pixel)
  if (selectedPixel) {
    const availablePixels = getAvailablePixels(accounts, selectedAdAccount, metaConnectionStatus)
    if (availablePixels.length > 0 && !availablePixels.some((x) => x.id === selectedPixel.id)) {
      selectedPixel = availablePixels[0]
    } else {
      selectedPixel = null
    }
  }

  let selectedInstagram = selectedAccounts.find(
    (x) => x.asset_class === AssetClass.profile && x.platform === Platform.instagram,
  )
  if (selectedInstagram) {
    const availableInstagram = getAvailableInstagramAccounts(
      accounts,
      selectedPage,
      metaConnectionStatus,
    )
    if (
      availableInstagram.length > 0 &&
      !availableInstagram.some((x) => x.id === selectedInstagram?.id)
    ) {
      selectedInstagram = availableInstagram[0]
    } else {
      selectedInstagram = null
    }
  }

  return {
    adAccount: selectedAdAccount,
    page: selectedPage,
    instagramAccount: selectedInstagram,
    pixel: selectedPixel,
  }
}

export default SelectAdPlatformForm
