import moment from 'moment'

export const EventStatus = Object.freeze({
  active: 'active',
  archived: 'archived',
})

export const EventCampaignStatus = Object.freeze({
  active: 'ACTIVE',
  paused: 'PAUSED',
})

export const CampaignOptimizationStatus = Object.freeze({
  active: 'ACTIVE',
  paused: 'PAUSED',
})

export function uniqueTasteClusterCount(suggestedTasteClusters) {
  return new Set(suggestedTasteClusters.filter((x) => x.tc)).size
}

export function hasActiveMarketingPlatformCampaign(event) {
  if (!event || !event.campaign_stop_time || !event.campaign_status) {
    return false
  }

  const marketingCampaignStopTime = moment(event.campaign_stop_time)

  return (
    event.campaign_status === EventCampaignStatus.active &&
    marketingCampaignStopTime.isSameOrAfter(moment())
  )
}

export function hasEndedMarketingPlatformCampaign(event) {
  if (!event || !event.campaign_stop_time || !event.campaign_status) {
    return false
  }

  const marketingCampaignStopTime = moment(event.campaign_stop_time)

  return (
    [EventCampaignStatus.active, EventCampaignStatus.paused].includes(event.campaign_status) &&
    (marketingCampaignStopTime.isBefore(moment()))
  )
}

export function hasPausedMarketingPlatformCampaign(event) {
  if (!event || !event.campaign_stop_time || !event.campaign_status) {
    return false
  }

  const marketingCampaignStopTime = moment(event.campaign_stop_time)

  return event.campaign_status === EventCampaignStatus.paused &&
    marketingCampaignStopTime.isSameOrAfter(moment())
}

export function getEventMarketingCampaignState(event) {
  const hasActiveCampaign = hasActiveMarketingPlatformCampaign(event)
  const hasPausedCampaign = hasPausedMarketingPlatformCampaign(event)
  const hasEndedCampaign = hasEndedMarketingPlatformCampaign(event)

  return {
    hasActiveCampaign,
    hasPausedCampaign,
    hasEndedCampaign,
    hasMarketingCampaign: hasActiveCampaign || hasPausedCampaign || hasEndedCampaign,
  }
}

export function isPastEvent(event) {
  return moment(event.date) < moment()
}

export function getEventFetchFilters(filters, page = 1) {
  const {
    search,
    dates,
    cities,
    venuesAndHalls = [],
    source,
    campaigns,
    status,
    include_archived = false,
    includePastEvents = false,
    descending = false,
    excludeProducts = false,
  } = filters

  const formattedCities = cities.map((x) => x.title)
  const formattedVenuesAndHalls = venuesAndHalls.map((x) => `${x.venue ?? ''};;${x.hall ?? ''}`)

  // fallback of default dates
  let {
    to: until = moment().subtract(3, 'year').format('DD-MM-YYYY'),
    from: since = moment().subtract(3, 'year').format('DD-MM-YYYY'),
  } = dates

  if (!includePastEvents) {
    since = moment.max([moment(since, 'DD-MM-YYYY'), moment()]).format('DD-MM-YYYY')
  } else {
    since = moment(since, 'DD-MM-YYYY').format('DD-MM-YYYY')
  }

  return {
    page,
    cities: formattedCities,
    vh: formattedVenuesAndHalls,
    source,
    frontend_status: status.value,
    marketing_campaign_status: campaigns,
    until,
    since,
    include_archived,
    descending,
    search,
    exclude_products: excludeProducts,
  }
}
