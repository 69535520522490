import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
  CancelledIcon,
  ChatBubbleOutlineIcon,
  FacebookShareIcon,
  MoreHorizIcon,
  PublicIcon,
  ThumbUpIcon,
} from 'images'
import { toUpper } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const FacebookPreview = ({ data, onLoaded, mobileView = false }) => {
  const bodyRef = useRef(null)
  const [isLongText, setIsLongText] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (bodyRef) setIsLongText(bodyRef.current.scrollHeight > bodyRef.current.clientHeight)
  }, [])

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState)
  }

  if (!data) return null

  const mainStyle = mobileView ? 'mobile' : ''
  const isTinyMedia = data.media.width < 320 && data.media.height < 200
  const url = data.destination_url.replace('www.', '')
  const { page_name, profile_picture_url } = data ?? {}

  const display = () => {
    if (data?.fb_info_loading) return <div className="fb-error loading" />
    else if (page_name)
      return (
        <p className="title">
          <strong>{page_name}</strong>
          <span className="subtitle">
            <span className="pr-1">Sponsored</span>
            <span>
              <PublicIcon width={15} />
            </span>
          </span>
        </p>
      )
    else return <div className="fb-error">{t('Recommendations.summary.fbPageInfoError')}</div>
  }

  return (
    <div className={`facebook ${mainStyle} p-0 pt-3`}>
      <div className="d-flex justify-content-between px-3">
        <div className="d-flex align-items-center flex-row pr-2 w-100">
          <div>
            {profile_picture_url ? (
              <img alt={page_name} className="profile_picture" src={profile_picture_url} />
            ) : (
              <div className={`icon ${data?.fb_info_loading ? 'loading' : ''}`} />
            )}
          </div>
          {display()}
        </div>
        <div className="actions d-flex align-items-baseline gap-2">
          <span>
            <MoreHorizIcon width={mobileView ? 24 : 30} />
          </span>
          {mobileView && (
            <span>
              <CancelledIcon width={18} />
            </span>
          )}
        </div>
      </div>
      <p className="px-3">
        <span ref={bodyRef} className={`${showMore ? 'text' : 'text_clipped'}`}>
          {data.body}
        </span>
        {mobileView && isLongText && (
          <span className="more" onClick={toggleShowMore}>
            {showMore ? 'See less' : 'See more'}
          </span>
        )}
      </p>
      <div>
        <div className={`link ${isTinyMedia ? 'tiny-media' : ''}`}>
          {data.media.type.includes('video') ? (
            <video
              className="media"
              src={data.media.url}
              autoPlay
              loop
              muted
              playsInline
              onLoad={onLoaded}
            />
          ) : (
            <LazyLoadImage
              className="media"
              alt={data.media.filename}
              src={data.media.url}
              height={data.media.height}
              width={data.media.width}
              onLoad={onLoaded}
            />
          )}
          <div className="order-now">
            <div className="text d-flex flex-column gap-1">
              <div className="url" title={data.destination_url}>
                {mobileView ? url : toUpper(url)}
              </div>
              <div className="headline">{data.headline}</div>
            </div>
            <div className="button">{data.call_to_action}</div>
          </div>
        </div>
        {mobileView && <div className="divider" />}

        <div className="interactions">
          <button>
            <ThumbUpIcon />
            <span>Like</span>
          </button>
          <button>
            <ChatBubbleOutlineIcon />
            <span>Comment</span>
          </button>
          <button>
            <FacebookShareIcon />
            <span>Share</span>
          </button>
        </div>
      </div>
    </div>
  )
}

FacebookPreview.propTypes = {
  data: PropTypes.object,
  mobileView: PropTypes.bool,
  onLoaded: PropTypes.func,
}

export default FacebookPreview
