import { getEnumMemberName } from 'utils/helpers'

export const tokenTypes = Object.freeze({
  user: 'USER',
  system: 'SYSTEM',
})

export const configurations = [
  {
    id: '701932711822378',
    type: tokenTypes.user,
    assetTypes: [],
    scopes: [
      'ads_management',
      'ads_read',
      'pages_show_list',
      'pages_read_engagement',
      'read_insights',
    ],
  },
  {
    id: '701932711822378',
    type: tokenTypes.user,
    assetTypes: ['facebook.profile'],
    scopes: [
      'ads_management',
      'ads_read',
      'pages_show_list',
      'pages_read_engagement',
      'read_insights',
    ],
  },
  {
    id: '560493096837278',
    type: tokenTypes.user,
    assetTypes: ['instagram.profile'],
    scopes: ['instagram_basic'],
  },
  {
    id: '146531595183594',
    type: tokenTypes.user,
    assetTypes: ['facebook.ad_account'],
    scopes: ['ads_management', 'ads_read'],
  },
  {
    id: '146531595183594',
    type: tokenTypes.user,
    assetTypes: ['facebook.pixel'],
    scopes: ['ads_management', 'ads_read'],
  },
  {
    id: '6874193192601970',
    type: tokenTypes.system,
    assetTypes: [],
    scopes: [
      'ads_management',
      'ads_read',
      'pages_show_list',
      'pages_read_engagement',
      'read_insights',
    ],
  },
  {
    id: '1655028388322191',
    type: tokenTypes.system,
    assetTypes: ['instagram.profile'],
    scopes: ['instagram_basic', 'instagram_manage_insights'],
  },
  {
    id: '1050856289562826',
    type: tokenTypes.system,
    assetTypes: ['facebook.pixel'],
    scopes: ['ads_management', 'ads_read'],
  },
  {
    id: '279603628384067',
    type: tokenTypes.system,
    assetTypes: ['facebook.ad_account'],
    scopes: ['ads_management', 'ads_read'],
  },
  {
    id: '650231873859952',
    type: tokenTypes.system,
    assetTypes: ['facebook.profile'],
    scopes: [
      'ads_management',
      'ads_read',
      'pages_show_list',
      'pages_read_engagement',
      'read_insights',
    ],
  },
]

export const assetCategories = [
  { key: 'facebook.profile', order: 0, required: true },
  { key: 'instagram.profile', order: 1, readOnly: true },
  { key: 'facebook.ad_account', order: 2 },
  { key: 'facebook.pixel', order: 3 },
]

export function getAssetKey(asset) {
  return `${getEnumMemberName(asset.platform)}.${getEnumMemberName(asset.asset_class)}`
}

export function getConfigurationByAssetCategoryKey(tokenType, assetCategoryKey) {
  return configurations.find(
    (configuration) =>
      configuration.type === tokenType && configuration.assetTypes.includes(assetCategoryKey),
  )
}

export function getAssetCategoryByConfiguration(configuration) {
  return assetCategories.find((assetCategory) =>
    configuration.assetTypes.includes(assetCategory.key),
  )
}
