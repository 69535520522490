/* eslint-disable indent */
import React, { createContext, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import metaAuthorizationApi, { metaAssetsApi } from 'api/connect/meta'
import { useReducerAsync } from 'hooks/useReducerAsync'
import { getAssetKey, tokenTypes } from './util'
import { getEnumMemberName } from 'utils/helpers'

/** @typedef {import('./types.js').ConnectContext} ConnectContext */
/** @typedef {import('./types.js').ConnectState} ConnectState */
/** @typedef {import('./types.js').Asset} Asset */
/** @typedef {import('./types.js').ConnectDispatchContext} ConnectDispatchContext */

/**
 * @type {React.Context<ConnectContext>}
 * @description Context for the Connect component
 */
export const ConnectContext = createContext(null)

/**
 * @type {React.Context<ConnectDispatchContext>}
 */
export const ConnectDispatchContext = createContext(null)

const FB_SESSIONS_KEY = 'fb_sessions'

export function get_fb_sessions_key() {
  const clientId = localStorage.getItem('clientId')
  return `${FB_SESSIONS_KEY}_${clientId}`
}

export const actions = {
  LOADING: 'LOADING',
  SET_STATUS: 'SET_STATUS',
  SET_ASSETS: 'SET_ASSETS',
  SET_ERROR: 'SET_ERROR',
  SET_WARNING: 'SET_WARNING',
  SET_ANONYMOUS: 'SET_ANONYMOUS',
  SET_FB_SESSION: 'SET_FB_SESSION',
  SET_FB_SESSIONS: 'SET_FB_SESSIONS',

  GET_STATUS: 'GET_STATUS',
  GET_ASSETS: 'GET_ASSETS',
  REFRESH_STATE: 'REFRESH_STATE',
  TOGGLE_ASSET: 'TOGGLE_ASSET',

  POST_ACCESS_TOKEN: 'POST_ACCESS_TOKEN',
  POST_AUTHORIZATION_CODE: 'POST_AUTHORIZATION_CODE',
  DEACTIVATE: 'DEACTIVATE',
}

/**
 * @type {ConnectState}
 * @description Initial state for the Connect component
 */
export const initialState = {
  loading: false,
  error: null,
  warning: null,
  assets: [],
  connections: [],
  assetWizardMode: false,
  singleLoginMode: false,
  isAnonymous: false,
  fbSessions: [
    {
      tokenType: tokenTypes.user,
      loggedIn: false,
    },
    {
      tokenType: tokenTypes.system,
      loggedIn: false,
    },
  ],
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_FB_SESSION: {
      const { tokenType, ...fbSession } = action.payload
      const fbSessions = state.fbSessions.filter((session) => session.tokenType !== tokenType)
      const newSessions = [...fbSessions, { tokenType, ...fbSession }]
      const sessionKey = get_fb_sessions_key()
      localStorage.setItem(sessionKey, JSON.stringify(newSessions))
      return { ...state, fbSessions: newSessions }
    }
    case actions.SET_FB_SESSIONS: {
      const sessionKey = get_fb_sessions_key()
      localStorage.setItem(sessionKey, JSON.stringify(action.payload))
      return { ...state, fbSessions: action.payload }
    }
    case actions.LOADING:
      return { ...state, loading: true, warning: null }
    case actions.SET_ERROR:
      return { ...state, loading: false, error: action.payload, warning: null }
    case actions.SET_WARNING:
      return { ...state, loading: false, warning: action.payload }
    case actions.SET_STATUS: {
      const { data, error } = action.payload
      return {
        ...state,
        connections: data?.connections ?? initialState.connections,
        error: error ?? state.error,
      }
    }
    case actions.SET_ASSETS: {
      const { data, error } = action.payload
      const assets = data?.map((asset) => ({ ...asset, key: getAssetKey(asset) })) ?? []
      return {
        ...state,
        loading: false,
        assets: assets,
        error: error ?? state.error,
        warning: null,
      }
    }
    case actions.SET_ANONYMOUS:
      return { ...state, isAnonymous: true }
    default:
      return state
  }
}

export const asyncActionHandlers = {
  [actions.GET_STATUS]:
    ({ dispatch }) =>
    async () => {
      const result = await metaAuthorizationApi.getStatus()
      dispatch({ type: actions.SET_STATUS, payload: result })
    },
  [actions.GET_ASSETS]:
    ({ dispatch, getState }) =>
    async () => {
      const { isAnonymous } = getState()
      const sessionKey = get_fb_sessions_key()
      let cachedFbSessions = localStorage.getItem(sessionKey)
      cachedFbSessions = cachedFbSessions ? JSON.parse(cachedFbSessions) : initialState.fbSessions
      if (isAnonymous && cachedFbSessions.every((session) => !session.loggedIn)) {
        dispatch({ type: actions.SET_ASSETS, payload: { data: [] } })
        return
      } 
      dispatch({ type: actions.LOADING })
      const platformUserIds = isAnonymous
        ? cachedFbSessions.filter((session) => session.loggedIn).map((session) => session.platformUserId)
        : null
      const result = await metaAssetsApi.listAssets({ platformUserIds })
      dispatch({ type: actions.SET_ASSETS, payload: result })
    },
  [actions.REFRESH_STATE]:
    ({ dispatch }) =>
    async () => {
      dispatch({ type: actions.GET_STATUS })
      dispatch({ type: actions.GET_ASSETS })
    },
  [actions.TOGGLE_ASSET]:
    ({ dispatch, getState }) =>
    async (action) => {
      const { assetId, enabled } = action.payload
      const { isAnonymous, fbSessions } = getState()
      dispatch({ type: actions.LOADING })
      await metaAssetsApi.toggleAsset(assetId, enabled)
      const platformUserIds = isAnonymous
        ? fbSessions.filter((session) => session.loggedIn).map((session) => session.platformUserId)
        : null
      const result = await metaAssetsApi.listAssets({ platformUserIds })
      dispatch({ type: actions.SET_ASSETS, payload: result })
    },
  [actions.POST_ACCESS_TOKEN]:
    ({ dispatch }) =>
    async (action) => {
      dispatch({ type: actions.LOADING })
      const { error } = await metaAuthorizationApi.postAccessToken(action.payload.accessToken)
      if (error) {
        dispatch({ type: actions.SET_ERROR, payload: error })
        return
      }

      dispatch({ type: actions.GET_STATUS })
      dispatch({ type: actions.GET_ASSETS })
    },
  [actions.POST_AUTHORIZATION_CODE]:
    ({ dispatch }) =>
    async (action) => {
      dispatch({ type: actions.LOADING })
      const { data, error } = await metaAuthorizationApi.postAuthorizationCode(action.payload)
      if (error) {
        dispatch({ type: actions.SET_ERROR, payload: error })
        return
      }

      dispatch({ type: actions.GET_STATUS })

      const sessionKey = get_fb_sessions_key()
      let cachedFbSessions = localStorage.getItem(sessionKey)
      cachedFbSessions = cachedFbSessions ? JSON.parse(cachedFbSessions) : initialState.fbSessions
      const tokenType = getEnumMemberName(data.token_type)
      const fbSession = {
        id: data.id,
        platformUserId: data.platform_user_id,
        expiresAt: data.expires_at,
        tokenType,
        loggedIn: true,
      }
      const fbSessions = [
        ...cachedFbSessions.filter((session) => session.tokenType !== tokenType),
        fbSession,
      ]

      dispatch({ type: actions.SET_FB_SESSIONS, payload: fbSessions })
      dispatch({ type: actions.GET_ASSETS })
    },
  [actions.DEACTIVATE]:
    ({ dispatch, getState }) =>
    async (action) => {
      const tokenType = action.payload.tokenType
      const { fbSessions, isAnonymous, singleLoginMode } = getState()
      if (singleLoginMode) {
        dispatch({ type: actions.LOADING })
        await metaAuthorizationApi.deactivate()
        dispatch({ type: actions.GET_STATUS })
        dispatch({ type: actions.GET_ASSETS })
      } else {
        if (isAnonymous) {
          dispatch({ type: actions.SET_ASSETS, payload: { data: [] } })
        }
        const sessions = fbSessions.filter(
          (session) => (session.tokenType === tokenType || !tokenType) && session.loggedIn,
        )
        if (!sessions.length) return

        dispatch({ type: actions.LOADING })
        if (tokenType) {
          await metaAuthorizationApi.deactivate(tokenType.toLowerCase(), sessions[0].platformUserId)
          dispatch({ type: actions.SET_FB_SESSION, payload: { tokenType, loggedIn: false } })
        } else {
          const userSession = sessions.find((session) => session.tokenType === tokenTypes.user)
          const systemSession = sessions.find((session) => session.tokenType === tokenTypes.system)
          if (userSession)
            await metaAuthorizationApi.deactivate(tokenTypes.user, userSession.platformUserId)
          if (systemSession)
            await metaAuthorizationApi.deactivate(tokenTypes.system, systemSession.platformUserId)
          dispatch({ type: actions.SET_FB_SESSIONS, payload: initialState.fbSessions })
        }
        dispatch({ type: actions.GET_STATUS })
        dispatch({ type: actions.GET_ASSETS })
      }
    },
}

export function dispatchGetAssets(dispatch) {
  dispatch({ type: actions.GET_ASSETS })
}

export function dispatchSetAnonymous(dispatch) {
  dispatch({ type: actions.SET_ANONYMOUS })
}

export function dispatchGetStatus(dispatch) {
  dispatch({ type: actions.GET_STATUS })
}

export function dispatchRefreshState(dispatch) {
  dispatch({ type: actions.REFRESH_STATE })
}

export function dispatchPostAccessToken(dispatch, accessToken) {
  dispatch({ type: actions.POST_ACCESS_TOKEN, payload: { accessToken } })
}

export function dispatchPostAuthCode(dispatch, code, redirectUri) {
  dispatch({ type: actions.POST_AUTHORIZATION_CODE, payload: { code, redirect_uri: redirectUri } })
}

export function dispatchSetError(dispatch, error) {
  dispatch({ type: actions.SET_ERROR, payload: error })
}

export function dispatchSetWarning(dispatch, warning) {
  dispatch({ type: actions.SET_WARNING, payload: warning })
}

export function dispatchToggleAsset(dispatch, assetId, enabled) {
  dispatch({ type: actions.TOGGLE_ASSET, payload: { assetId, enabled } })
}

export function dispatchDeactivate(dispatch, tokenType = null) {
  dispatch({ type: actions.DEACTIVATE, payload: { tokenType } })
}

export const ConnectContextProvider = ({ children, singleLoginMode = false }) => {
  const [state, dispatch] = useReducerAsync(reducer, {
    ...initialState,
    singleLoginMode,
  }, asyncActionHandlers)
  const { connections } = state

  useEffect(() => {
    const sessionKey = get_fb_sessions_key()
    let fbSessions = localStorage.getItem(sessionKey)
    if (!fbSessions) return

    const checkTokenStatusAsync = async (tokenId, tokenType) => {
      const response = await metaAuthorizationApi.getTokenStatus(tokenId)
      if (!response.data?.is_valid) {
        dispatch({ type: actions.SET_FB_SESSION, payload: { tokenType, loggedIn: false } })
      }
    }

    fbSessions = JSON.parse(fbSessions)

    const sessions = fbSessions.map((session) => {
      const hasExpired = session.expiresAt && Date.now() > session.expiresAt
      return hasExpired ? { ...session, loggedIn: false } : session
    })

    sessions
      .filter((session) => session.loggedIn)
      .forEach((session) => {
        checkTokenStatusAsync(session.id, session.tokenType)
      })

    dispatch({ type: actions.SET_FB_SESSIONS, payload: sessions })
  }, [dispatch])

  const isConnected = useCallback(() => {
    const hasValidUserToken = connections.some(
      (connection) => connection.token_type === tokenTypes.user,
    )
    const hasValidSystemUserToken = connections.some(
      (connection) => connection.token_type === tokenTypes.system,
    )

    return hasValidUserToken || hasValidSystemUserToken
  }, [connections])

  const isLogged = useCallback(() => {
    return state.fbSessions.some((session) => session.loggedIn)
  }, [state])

  const isAssetOwner = useCallback(
    /**
     * @param {Asset} asset
     */
    (asset) => {
      if (asset.asset_users.length === 0) return false

      const sessions = state.fbSessions.filter((session) => session.loggedIn)
      if (!sessions.length) return false

      const userSession = sessions.find((session) => session.tokenType === tokenTypes.user)
      const systemSession = sessions.find((session) => session.tokenType === tokenTypes.system)

      return (
        asset.asset_users.some((user) => user.platform_user_id === userSession?.platformUserId) ||
        asset.asset_users.some((user) => user.platform_user_id === systemSession?.platformUserId)
      )
    },
    [state],
  )

  return (
    <ConnectContext.Provider value={state}>
      <ConnectDispatchContext.Provider value={{ dispatch, isConnected, isLogged, isAssetOwner }}>
        {children}
      </ConnectDispatchContext.Provider>
    </ConnectContext.Provider>
  )
}

ConnectContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  singleLoginMode: PropTypes.bool,
}

export function useConnectContext() {
  const state = React.useContext(ConnectContext)

  if (!state) {
    throw new Error('useConnectContext must be used within a ConnectContextProvider')
  }

  return state
}

export function useConnectDispatchContext() {
  const dispatch = React.useContext(ConnectDispatchContext)

  if (!dispatch) {
    throw new Error('useConnectDispatchContext must be used within a ConnectContextProvider')
  }

  return dispatch
}
