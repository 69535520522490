import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { HeaderRenderer } from 'react-data-grid'

import { useSentinelContext } from '../SentinelProvider'
import { numberWithThousandsSeparators } from 'utils/helpers'

function AggregatedValueLabel({ value }) {
  return <div className="aggregated-value-label">{numberWithThousandsSeparators(value)}</div>
}

AggregatedValueLabel.propTypes = {
  value: PropTypes.string.isRequired,
}

export default function ColumnHeaderWithAggregatedValue({ column }) {
  const {
    results: { aggregations },
    aggregationType,
  } = useSentinelContext()

  const value = useMemo(() => {
    const aggregatedValue = aggregations.find(x => x.type === column.key)
    return aggregatedValue ? aggregatedValue[aggregationType] : ''
  }, [aggregations, column.key, aggregationType])

  return (
    <div className={`column-header-with-aggregated-value ${column.aggregatedCellClass}`}>
      <AggregatedValueLabel value={value} />
      <HeaderRenderer column={column} />
    </div>
  )
}

ColumnHeaderWithAggregatedValue.propTypes = {
  column: PropTypes.object.isRequired,
}
