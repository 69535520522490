import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import throttle from 'lodash.throttle'
import { Card } from 'react-bootstrap'

import {
  initStateArray,
  arrayReducer,
  objectReducer,
  initStateObject,
  OVERWRITE,
} from '../../reducers/default'
import { fetchEvent, fetchEvents, fetchFlaggedEvents, getEventSeries } from '../../api/events'

import SideDrawer from '../Shared/SideDrawer'

import EventList from '../Event/List/EventList'
import LoadingSpinner from '../Shared/LoadingSpinner'
import ErrorMessage from '../Shared/ErrorMessage'
import InfoBox from '../Shared/InfoBox'

import { EventRowButton } from '../Shared/Button'
import EventDetails from '../Event/Details/EventDetails'
import EventRow from '../Event/List/EventRow'

import emptyIcon from '../../images/icons/empty-events.svg'
import AddEvent from 'components/AddEvent/AddEvent'
import { EventStatsProvider } from 'components/Event/Details/EventStatsProvider'
import Recommendation from 'components/Recommendations/Campaign/Recommendation'
import { CampaignMenuContextProvider } from 'components/Recommendations/Campaign/CampaignMenu/CampaignMenuContext'
import EventAttributionModelContextProvider from 'domain/events/attributionModel/EventAttributionModelContextProvider'
import useHasPermission, { Permissions } from 'hooks/useHasPermission'

const pageInit = { number: 1, loading: false }
function EventButtons({ eventId, eventCategory }) {
  const hasLookoutAccess = useHasPermission(Permissions.lookout) && eventCategory !== 'product'
  const hasWaveAccess = useHasPermission(Permissions.wave)
  return (
    <div data-testid="event-row-icon-btns" className="event-row_right-content_wrapper">
      <InfoBox
        content="Recommendations.lookoutBtn"
        withAnchor={false}
        showBody={false}
        tooltipClassNames="event-row__tooltip"
        enabled={hasLookoutAccess}
      >
        <EventRowButton
          classNames={hasLookoutAccess ? '' : 'invisible'}
          linkTo={
            window.innerWidth < 976
              ? `/events/${eventId}`
              : `/events/?drawer-open=true&event-id=${eventId}`
          }
          // linkTo={`/events/${id}`}
          showIcon
          type={'LOOKOUT'}
          disabled={!hasLookoutAccess}
        />
      </InfoBox>

      <InfoBox
        content="Recommendations.wavesBtn"
        withAnchor={false}
        showBody={false}
        tooltipClassNames="event-row__tooltip"
      >
        <EventRowButton
          linkTo={
            window.innerWidth < 976
              ? `/campaigns/${eventId}`
              : `/campaigns/?drawer-open=true&event-id=${eventId}`
          }
          extraStyles={{ marginLeft: '10px' }}
          type={'WAVES'}
          showIcon
          disabled={!hasWaveAccess}
        />
      </InfoBox>
    </div>
  )
}

EventButtons.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventCategory: PropTypes.string.isRequired,
}

const EventsEmptyView = () => {
  const { t } = useTranslation()
  const hasSimulationAccess = useHasPermission(Permissions.simulation)

  return (
    <>
      <div className="empty-events">
        <img src={emptyIcon} alt="No events" />
        <p className="title">{t('Events.empty.title')}</p>
        <p className="description">{t('Events.empty.description')}</p>
        {hasSimulationAccess ? <AddEvent /> : null}
      </div>
    </>
  )
}

/**
 * Upcoming events for Year, Month etc.
 * @Tags( dashboard, api )
 * @Endpoints( /events )
 * @ApiLogic( ../../api/events )
 */
const UpcomingEvents = ({ filter }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState({ ...pageInit })
  const [events, dispatch] = useReducer(arrayReducer, initStateArray)
  const [flaggedEvents, flaggedDispatch] = useReducer(arrayReducer, initStateArray)
  const [selectedEvent, eventDispatch] = useReducer(objectReducer, initStateObject)
  const [eventSeries, dispatchEventSeries] = useReducer(objectReducer, initStateObject)
  const empty = events.success && events.items.length === 0
  const [drawerOpen, setDrawerOpen] = useState(false)
  const hasLookoutAccess = useHasPermission(Permissions.lookout)
  const hasWaveAccess = useHasPermission(Permissions.wave)
  const targetUrlPrefix = hasLookoutAccess ? 'events' : hasWaveAccess ? 'campaigns' : null

  const isDefaultFilter = filter === moment().add(1, 'year').format('DD-MM-YYYY')

  useEffect(() => {
    if (!drawerOpen) {
      if (!selectedEvent.loading) eventDispatch({ type: OVERWRITE, payload: initStateObject })

      return
    }

    const urlParams = new URL(window.location.href)
    const eventId = urlParams.searchParams.get('event-id')
    if (selectedEvent.content.id === eventId) return

    eventId && fetchEvent(eventDispatch, eventId)
  }, [drawerOpen, selectedEvent.loading, selectedEvent.content.id])

  useEffect(() => {
    setPage({ ...pageInit })
    fetchFlaggedEvents(flaggedDispatch)
    fetchEvents(dispatch, {
      since: moment().format('DD-MM-yyyy'),
      until: filter,
      removeCancelled: true,
      frontend_status: ['DEFAULT', 'FLAGGED'],
    })
  }, [filter])

  useEffect(() => {
    const handleScroll = () => {
      if (events.maxPage > page.number) {
        if (document.body.scrollHeight - (window.innerHeight + window.scrollY) < 300) {
          if (!page.loading) {
            loadNextPage()
          }
        }
      }
    }
    handleScroll()
    const throttledScroll = throttle(handleScroll, 5)

    window.addEventListener('scroll', throttledScroll)
    return () => window.removeEventListener('scroll', throttledScroll)
  })

  useEffect(() => {
    if (selectedEvent.content?.id) getEventSeries(dispatchEventSeries, selectedEvent.content?.id)
  }, [selectedEvent])

  const loadNextPage = () => {
    if (page.loading) return

    const fetchNewPageAsync = async (pageNumber) => {
      await fetchEvents(dispatch, {
        page: pageNumber,
        until: filter,
        since: moment().format('DD-MM-yyyy'),
        removeCancelled: true,
        frontend_status: ['DEFAULT', 'FLAGGED'],
      })
      setPage((page) => ({ ...page, loading: false }))
    }

    const pageNumber = page.number + 1
    setPage({ number: pageNumber, loading: true })
    fetchNewPageAsync(pageNumber)
  }

  return (
    <>
      {/* if there is no "FLAGGED" events don't show the slider*/}
      {flaggedEvents.items.length > 0 ? (
        <div className="events-list card card-default card-upcoming-events mb-4">
          <Card.Header style={{ background: 'white' }}>
            <InfoBox content="Tips.Dashboard.FlaggedEvents">
              <h2 className="card-title-default d-inline-block">
                {t('Dashboard.flaggedEventListTitle')}
              </h2>
            </InfoBox>
          </Card.Header>
          <div className="card-body">
            <EventList
              events={flaggedEvents.items}
              rowRender={(event) => (
                <EventRow
                  event={event}
                  key={event.id}
                  onClick={(eventId) => {
                    eventId && fetchEvent(eventDispatch, eventId)
                  }}
                  isSelected={selectedEvent.content.id === event.id}
                  isDisabled={!hasLookoutAccess && !hasWaveAccess}
                  contentClassNames="dashboard"
                  targetUrlPrefix={targetUrlPrefix}
                />
              )}
            />
          </div>
        </div>
      ) : null}
      <div
        className="events-list card card-default card-upcoming-events"
        data-testid="events-list-test"
      >
        <Card.Header>
          <InfoBox content="Tips.Dashboard.ImportantEvents">
            <h2 className="card-title-default d-inline-block">{t('Dashboard.eventListTitle')}</h2>
          </InfoBox>
        </Card.Header>
        <div className="card-body">
          {events.loading && <LoadingSpinner className="mt-4" />}
          {events.error && <ErrorMessage>{t(events.message)}</ErrorMessage>}
          {/*  we only show events WITHOUT a status in this list  */}
          <EventList
            events={events.items.filter((e) => e.frontend_status === 'DEFAULT')}
            rowRender={(event) => (
              <EventRow
                event={event}
                key={event.id}
                onClick={(eventId) => {
                  eventId && fetchEvent(eventDispatch, eventId)
                }}
                isSelected={selectedEvent.content.id === event.id}
                isDisabled={!hasLookoutAccess && !hasWaveAccess}
                contentClassNames="dashboard"
                targetUrlPrefix={targetUrlPrefix}
              />
            )}
          />
          {events.loading_page && <LoadingSpinner className="mt-4" />}
          {empty && !isDefaultFilter && (
            <p className="events-list__empty text-center py-3">{t('Dashboard.emptyEventList')}</p>
          )}
        </div>
      </div>
      {empty && isDefaultFilter && <EventsEmptyView />}
      <SideDrawer
        drawerOpen={drawerOpen}
        toggle={setDrawerOpen}
        classes="no-padding"
      >
        {hasLookoutAccess &&
          !!selectedEvent.content.category &&
          selectedEvent.content.category !== 'product' &&
          hasWaveAccess && (
          <EventAttributionModelContextProvider eventId={selectedEvent.content.id}>
            <EventStatsProvider eventId={selectedEvent.content?.id}>
              <CampaignMenuContextProvider event={selectedEvent.content}>
                <EventDetails event={selectedEvent.content} eventSeries={eventSeries.content} />
              </CampaignMenuContextProvider>
            </EventStatsProvider>
          </EventAttributionModelContextProvider>
        )}
        {!!selectedEvent.content?.id &&
          ((!hasLookoutAccess && hasWaveAccess) ||
            selectedEvent.content.category === 'product') && (
          <CampaignMenuContextProvider event={selectedEvent.content}>
            <Recommendation event={selectedEvent.content} />
          </CampaignMenuContextProvider>
        )}
      </SideDrawer>
    </>
  )
}

UpcomingEvents.propTypes = {
  /**
   * Current time range
   */
  filter: PropTypes.string.isRequired,
}

export default UpcomingEvents
