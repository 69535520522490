import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import InfoBox from 'components/Shared/InfoBox'
import Notice from 'components/Shared/Notice'
import i18n from 'i18n'
import { hasPrismaAccessTier } from 'hooks/useHasPermission'

const CampaignRuntime = ({ event, dates, totalRuntime, updateDate }) => {
  const { t } = useTranslation()
  const maxDate = useMemo(() => new Date(event.date), [event])
  const minRuntime = 14

  return (
    <div>
      <InfoBox content="Tips.Recommendations.Runtime">
        <h2 className="card-title-default d-inline-block event-header-title">
          {t('Tips.Recommendations.Runtime.title')}
        </h2>
      </InfoBox>
      <div className="runtime">
        <div className="runtime_inputs">
          <div className="runtime_inputs_col">
            <h6>{t('Recommendations.CampaignRuntime.startDate')}</h6>
            <div className="form-group input-cell input-cell--input input-cell--date separatePlaceholderBox ">
              <DatePicker
                name={t('Recommendations.CampaignRuntime.startDate')}
                minDate={new Date()}
                maxDate={dates.to ?? maxDate}
                separatePlaceholder={true}
                selected={dates.from ? dates.from : moment().subtract(1, 'month').toDate()}
                placeholderText={t('Recommendations.CampaignRuntime.startDate')}
                onChange={(date) => date && updateDate(date, 'from')}
                dateFormat="dd.MM.yyyy"
                locale={i18n.language}
                required
                autoComplete="off"
                style={{ border: 'none' }}
                selectsStart
                startDate={dates.from ?? new Date()}
                endDate={dates.to ?? maxDate}
              />
            </div>
          </div>
          <div className="runtime_inputs_col">
            <h6>{t('Recommendations.CampaignRuntime.endDate')}</h6>
            <div className="form-group input-cell input-cell--input input-cell--date separatePlaceholderBox ">
              <DatePicker
                name={t('Recommendations.CampaignRuntime.endDate')}
                minDate={dates.from}
                separatePlaceholder={true}
                selected={dates.to ? dates.to : moment().subtract(1, 'month').toDate()}
                placeholderText={t('Recommendations.CampaignRuntime.endDate')}
                onChange={(date) => date && updateDate(date, 'to')}
                dateFormat="dd.MM.yyyy"
                locale={i18n.language}
                required
                autoComplete="off"
                style={{ border: 'none' }}
                selectsEnd
                startDate={dates.from ?? new Date()}
                endDate={dates.to ?? maxDate}
              />
            </div>
          </div>
          <div className="runtime_inputs_col">
            <h6 style={{ marginBottom: 22 }}>{t('Recommendations.CampaignRuntime.totalDays')}</h6>
            <label>{dates.from && dates.to && Math.max(totalRuntime, 0)}</label>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center gap-2 ml-md-3">
          <Notice
            message={t('Recommendations.CampaignRuntime.alert')}
            isVisible={
              totalRuntime < minRuntime && !hasPrismaAccessTier() && event.category !== 'product'
            }
          />
          <Notice
            message={t('Recommendations.CampaignRuntime.beyondEventDateAlert')}
            isVisible={dates.to && moment(dates.to).isAfter(maxDate)}
          />
        </div>
      </div>
    </div>
  )
}

CampaignRuntime.propTypes = {
  event: PropTypes.object,
  dates: PropTypes.object,
  updateDate: PropTypes.func,
  totalRuntime: PropTypes.number,
}

export default CampaignRuntime
