import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import Asset from './Asset'
import { dispatchSetWarning, useConnectContext, useConnectDispatchContext } from './connect.js'
import { assetCategories, tokenTypes } from './util'
import ErrorFallback from 'components/Shared/ErrorFallback'
import { getEnumMemberName } from 'utils/helpers'

export default function Assets({
  assetCategory,
  tokenType,
  onToggleAsset,
  onAddAsset,
  disabled = false,
}) {
  const { t } = useTranslation()

  const { assets: allAssets, connections, isAnonymous } = useConnectContext()
  const { dispatch, isAssetOwner, isLogged } = useConnectDispatchContext()

  const hasValidUserToken = useMemo(
    () => connections.some((connection) => connection.token_type === tokenTypes.user),
    [connections],
  )
  const hasValidSystemUserToken = useMemo(
    () => connections.some((connection) => connection.token_type === tokenTypes.system),
    [connections],
  )

  if (isAnonymous && !isLogged()) {
    return null
  }

  if (tokenType === tokenTypes.user && !hasValidUserToken) {
    return null
  }

  if (tokenType === tokenTypes.system && !hasValidSystemUserToken) {
    return null
  }

  const assets = allAssets.filter(
    (asset) =>
      (isAssetOwner(asset) || asset.is_enabled) &&
      (!assetCategory || asset.key === assetCategory.key) &&
      // User token type: show only user assets, or unbundled assets
      ((tokenType === tokenTypes.user &&
        asset.asset_users.some((x) => getEnumMemberName(x.token_type) === tokenTypes.user)) ||
        // System token type: show only system assets
        (tokenType === tokenTypes.system &&
          asset.asset_users.some((x) => getEnumMemberName(x.token_type) === tokenTypes.system))),
  )

  const sortedAssets = assets.sort((a, b) => {
    const indexA = assetCategories.find((category) => category.key === a.key)?.order
    const indexB = assetCategories.find((category) => category.key === b.key)?.order

    return indexA - indexB
  })

  const assetsToRender = assetCategories.reduce((acc, category) => {
    const categoryKey = category.key
    if (assetCategory && categoryKey !== assetCategory.key) {
      return acc
    }

    const categoryAssets = sortedAssets
      .filter((asset) => asset.key === categoryKey)
      .sort((a, b) => {
        if (a.date_created === b.date_created) {
          return a.asset_label.localeCompare(b.asset_label)
        }

        return new Date(a.date_created) - new Date(b.date_created)
      })
      .map((asset) => {
        const platformUsers = asset.asset_users.map((user) => user.platform_user_id)
        const usernames = connections
          .filter(
            (connection) =>
              connection.token_type === tokenType &&
              platformUsers.includes(connection.platform_user_id),
          )
          .map((connection) => connection.username)

        return {
          ...asset,
          usernames,
        }
      })

    if (!categoryAssets.length && category.readOnly) {
      // If the category is read-only and there are no assets, don't render it
      return acc
    }

    if (!acc[categoryKey]) {
      acc[categoryKey] = []
    }

    acc[categoryKey].push(...categoryAssets)

    return acc
  }, {})

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {Object.keys(assetsToRender).map((assetKey, index) => (
        <React.Fragment key={assetKey}>
          <h3 className="meta_assets-category__title settings-page__section border-0 mb-0 pb-3">
            {t(`Settings.MetaAccess.Assets.${assetKey}.title`)}
          </h3>
          {assetsToRender[assetKey].map((asset) => (
            <div className="settings-page__section meta_assets_asset border-0" key={asset.id}>
              <Asset
                asset={asset}
                onToggle={(assetId, enabled) => {
                  const required = assetCategories.find((x) => x.key === assetKey)?.required
                  const assets = assetsToRender[assetKey]
                  if (required && assets.filter((x) => x.is_enabled).length === 1 && !enabled) {
                    dispatchSetWarning(dispatch, t('Settings.MetaAccess.atLeastOnePageIsRequired'))
                    return
                  }

                  onToggleAsset(assetId, enabled)
                }}
                disabled={disabled}
              />
            </div>
          ))}
          {!assetCategories.find((x) => x.key === assetKey)?.readOnly && <div className="settings-page__section border-0 pt-3">
            <button
              type="button"
              className="meta_assets_add-asset-btn txt-btn"
              aria-label="Add Asset"
              onClick={() => onAddAsset(assetKey)}
            >
              <span>+</span> {t(`Settings.MetaAccess.Assets.${assetKey}.addAction`)}
            </button>
          </div>}
          {index !== Object.keys(assetsToRender).length - 1 && (
            <div className="meta_assets_category-separator" />
          )}
        </React.Fragment>
      ))}
    </ErrorBoundary>
  )
}

Assets.propTypes = {
  assetCategory: PropTypes.object,
  tokenType: PropTypes.oneOf(Object.values(tokenTypes)).isRequired,
  onToggleAsset: PropTypes.func.isRequired,
  onAddAsset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
