import React, { useState, useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { initStateObject, objectReducer } from '../../reducers/default'
import { authenticateUser, fetchFeatures, fetchPermissions, fetchUserProfile } from '../../api/auth'
import { checkNewMessages } from '../../api/messages'

import LoginSuccess from './LoginSuccess'
import AccountDisabled from './AccountDisabled'
import Modal from '../Shared/Modal'
import { fetchPartnerDetails } from 'api/partner'
import PartnerSelect from './PartnerSelect'
import FormHeader from 'components/Authentication/FormHeader'
import { UPDATE_PARTNER } from 'reducers/user'
import { metaAssetsApi } from 'api/connect/meta'
import { get_fb_sessions_key } from 'components/Settings/Meta/connect'

const initialState = {
  email: '',
  password: '',
  isLogged: false,
}

/**
 * Login form for the application (connected to Cognito AWS in the Backend).
 * @Tags( cognito, api )
 * @Inputs( email, password )
 * @Endpoints( /auth, /features )
 * @ApiLogic( ../../api/auth )
 */
const Form = ({ state, formStatus, handleSubmit, t, handleChange }) => {
  const { email, password } = state
  const [errorModalOpen, setErrorModalOpen] = useState(
    formStatus.error && formStatus.message === 'error.auth.inactive',
  )

  useEffect(() => {
    if (formStatus.error && formStatus.message === 'error.auth.inactive' && !errorModalOpen) {
      setErrorModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStatus])

  return (
    <>
      <form onSubmit={handleSubmit}>
        {formStatus.error && formStatus.message && formStatus.message !== 'error.auth.inactive' && (
          <p data-testid="errorForm" className="alert alert-danger">
            {t(formStatus.message)}
          </p>
        )}
        <div className="form-group">
          <input
            data-cy="username-input-cy"
            type="email"
            className="form-control s-50"
            id="email"
            value={email}
            onChange={(e) => handleChange(e)}
            placeholder={t('common.email')}
            autoFocus
          />
        </div>
        <div className="form-group">
          <input
            data-cy="password-input-cy"
            type="password"
            className="form-control s-50"
            id="password"
            value={password}
            onChange={(e) => handleChange(e)}
            placeholder={t('common.password')}
          />
        </div>
        <div className="forgot-password">
          <Link data-cy="forgot-password-link-cy" to="/forgot-password">
            {t('Login.form.forgotPassword')}
          </Link>
        </div>
        <button
          data-cy="login-submit-button-cy"
          type="submit"
          className="bt bt-navy s-50"
          style={{ width: '100%' }}
        >
          {formStatus.loading ? t('Login.form.btnLoading') : t('Login.form.btn')}
        </button>
      </form>
      {errorModalOpen && (
        <Modal
          closeCallback={() => setErrorModalOpen(false)}
          hideCloseBtn
          title={''}
          mainContent={<AccountDisabled closeModal={() => setErrorModalOpen(false)} />}
          fullWidth={false}
          timeoutCloseCallback={() => {}}
        />
      )}
    </>
  )
}

Form.propTypes = {
  state: PropTypes.object,
  formStatus: PropTypes.object,
  handleSubmit: PropTypes.func,
  t: PropTypes.func,
  handleChange: PropTypes.func,
}

const FormPage = ({ title = '', subtitle = '', children }) => {
  return (
    <>
      <FormHeader title={title} subtitle={subtitle} />
      <div className="form-wrapper">{children}</div>
    </>
  )
}

FormPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
}

const LoginForm = () => {
  const { t, i18n } = useTranslation()
  const [state, setState] = useState(initialState)
  const [showSplash, setShowSplash] = useState(false)
  const [selectedPartner, setSelectedPartner] = useState(null)
  const user = useSelector((state) => state.user)
  const [formStatus, setFormStatus] = useReducer(objectReducer, initStateObject)
  const dispatch = useDispatch()

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email, password } = state

    const sendFormAsync = async () => {
      try {
        authenticateUser(dispatch, { username: email, password: password }, setFormStatus)
      } catch (error) {
        console.log('Login error.')
      }
    }
    sendFormAsync()
  }

  useEffect(() => {
    const fetchFeaturesAndRedirect = async () => {
      await fetchFeatures(dispatch)
      await fetchPermissions(dispatch)
      await fetchUserProfile(dispatch, i18n)
      await checkNewMessages(dispatch)
      await fetchPartnerDetails(dispatch)

      setTimeout(() => {
        setState((prevState) => {
          return {
            ...prevState,
            isLogged: true,
            accessLevel: user.accessLevel,
          }
        })
      }, 750)
    }

    if (user.cognitoGroups && user.cognitoGroups.length <= 1) {
      const partner = user.cognitoGroups[0]
      setSelectedPartner(partner)
      localStorage.setItem('clientId', partner)
      dispatch({ type: UPDATE_PARTNER, payload: partner })
    }
    if (selectedPartner) {
      setShowSplash(true)
      fetchFeaturesAndRedirect()
    }
  }, [
    user.isLogged,
    user.accessLevel,
    user.cognitoGroups,
    dispatch,
    i18n,
    showSplash,
    selectedPartner,
  ])

  useEffect(() => {
    const triggerAssetRefetchAsync = async () => {
      let cachedFbSessions = localStorage.getItem(get_fb_sessions_key())
      cachedFbSessions = cachedFbSessions ? JSON.parse(cachedFbSessions) : []
      if (!cachedFbSessions.length) {
        return
      }
      await metaAssetsApi.triggerAssetRefetch({
        platformUserIds: cachedFbSessions
          .filter((x) => x.loggedIn)
          .map((session) => session.platformUserId),
      })
    }

    if (user.isLogged && selectedPartner) {
      triggerAssetRefetchAsync()
    }
  }, [user.isLogged, selectedPartner])

  if (!user.isLogged) {
    return (
      <FormPage title={t('Login.form.title')} subtitle={t('Login.form.subtitle')}>
        <Form
          state={state}
          formStatus={formStatus}
          handleSubmit={handleSubmit}
          t={t}
          handleChange={handleChange}
        />
      </FormPage>
    )
  } else if (selectedPartner || user.cognitoGroups?.length === 1) {
    return (
      <FormPage title={t('Login.form.title')}>
        <LoginSuccess state={state} showSplash={showSplash} setShowSplash={setShowSplash} />
      </FormPage>
    )
  } else {
    return (
      <FormPage
        title={t('Login.form.partnerSelectTitle')}
        subtitle={t('Login.form.partnerSelectSubtitle')}
      >
        <PartnerSelect
          setSelectedPartner={setSelectedPartner}
          dispatch={dispatch}
          cognitoGroups={user.cognitoGroups}
        />
      </FormPage>
    )
  }
}

export default LoginForm
