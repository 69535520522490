import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { fetchCampaigns } from 'api/campaigns'
import { usePagedList } from 'hooks/usePagedList'

import PortalModal from 'components/Shared/PortalModal'
import CancelSetupModalContent from '../../CancelSetupModalContent'
import ErrorMessage from 'components/Shared/ErrorMessage'
import { Accounts, Ads, Summary } from '..'
import { throttle } from 'lodash'
import {
  useCampaignSetupDispatch,
  useCampaignSetup,
} from 'components/Recommendations/Campaign/Context/CampaignSetupContext'

function SetupError() {
  const { t } = useTranslation()
  const { error } = useCampaignSetup()
  if (!error) return null

  if (typeof error === 'string') {
    return <ErrorMessage danger>{t(error)}</ErrorMessage>
  }

  return <ErrorMessage danger>{error}</ErrorMessage>
}

const SetupWrapper = ({ event, onNext, onBack, boost, child, setCampaigns }) => {
  const { setup } = useCampaignSetup()
  const { cancelSetup } = useCampaignSetupDispatch()
  const [showCancelSetupModal, setShowCancelSetupModal] = useState(false)

  const fetchCampaignsPage = useCallback((dispatch, eventId, page, controller) => {
    fetchCampaigns(dispatch, eventId, page, null, controller)
  }, [])

  const { list: allCampaigns } = usePagedList(fetchCampaignsPage, {
    params: event.id,
    containerSelector: '#campaigns-container',
  })

  useEffect(() => {
    setCampaigns(allCampaigns.items)
  }, [allCampaigns, setCampaigns])

  useEffect(() => {
    const sideDrawerComponent = document.getElementById('side-drawer-content')

    const handleScroll = () => {
      const buttonContainerElem = document.getElementById('setup-btn-container')
      if (!buttonContainerElem) return
      const extraOffset = 20
      if (
        sideDrawerComponent.offsetHeight + sideDrawerComponent.scrollTop + extraOffset >=
        sideDrawerComponent.scrollHeight
      )
        // remove box shadow when scrolled to bottom
        buttonContainerElem.style.boxShadow = 'none'
      else {
        buttonContainerElem.style.boxShadow = '1px -3px 8px rgba(0, 0, 0, 0.5)'
      }
    }
    const throttledScroll = throttle(handleScroll, 200)

    if (sideDrawerComponent) sideDrawerComponent.addEventListener('scroll', throttledScroll)

    return () => {
      if (sideDrawerComponent) sideDrawerComponent.removeEventListener('scroll', throttledScroll)
    }
  }, [])

  

  if (!setup) {
    return null
  }

  const selectedTcs = new Set(setup?.creatives?.map((c) => c.tc))
  const campaigns = allCampaigns.items.filter((campaign) => selectedTcs.has(campaign.tc))

  const renderElements = () => {
    switch (child) {
      case 'Accounts':
        return (
          <Accounts
            event={event}
            setShowCancelSetupModal={setShowCancelSetupModal}
            onBack={onBack}
            onNext={onNext}
          />
        )
      case 'Ads':
        return (
          <Ads
            event={event}
            campaigns={campaigns}
            setShowCancelSetupModal={setShowCancelSetupModal}
            onNext={onNext}
            onBack={onBack}
          />
        )
      case 'Summary':
        return (
          <Summary
            campaigns={campaigns}
            event={event}
            boost={boost}
            onBack={onBack}
            setShowCancelSetupModal={setShowCancelSetupModal}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <SetupError />
      {renderElements()}
      <PortalModal
        isOpen={showCancelSetupModal}
        onClickOutside={() => setShowCancelSetupModal(false)}
      >
        <CancelSetupModalContent
          onCancel={() => setShowCancelSetupModal(false)}
          onConfirm={cancelSetup}
        />
      </PortalModal>
    </>
  )
}

SetupWrapper.propTypes = {
  event: PropTypes.object.isRequired,
  initialBudget: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  boost: PropTypes.func.isRequired,
  setCampaigns: PropTypes.func.isRequired,
  child: PropTypes.string.isRequired,
  initialGoal: PropTypes.string,
}

export default SetupWrapper
