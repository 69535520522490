import store from '../store'
import moment from 'moment'

import { renewToken } from '../api/auth'
import { LOGOUT } from '../reducers/user'

export const getAuthorizationHeader = () => {
  const state = store.getState()
  if (state.user.isLogged) {
    const now = moment()
    const tokenExpirationDate = moment(state.user.tokenExpirationDate)

    const timeDifferenceMins = moment.duration(moment(tokenExpirationDate).diff(moment(now))).asMinutes()

    const renewXMinutesBefore = 5

    // This should be manged by 401 error at the BE
    if (timeDifferenceMins < 0) {
      store.dispatch({ type: LOGOUT })
      return null
    }

    if (timeDifferenceMins < renewXMinutesBefore) {
      renewUserToken(state.user.refreshToken)
    }

    if (state.user.accessToken) {
      return 'Bearer ' + state.user.accessToken
    }
  }

  store.dispatch({ type: LOGOUT })
  return null
}

let skip = false
export const renewUserToken = async (refreshToken) => {
  if (skip) {
    return
  }
  skip = true

  try {
    await renewToken(store.dispatch, refreshToken)
  } catch (error) {
    console.error('Token renewal failed:', error)
  } finally {
    skip = false
  }
}
